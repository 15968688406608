import React, {memo} from 'react';

import {Typography} from '../../Typography';

import {makeStyles} from '@material-ui/styles';
import {incline} from '../../../utils';

interface Props {
  imageUrl: string;
  title: string;
  experience: number;
}

const useStyles = makeStyles({
  root: {
    padding: '24px 0',
    display: 'flex',

    '&:first-child': {
      paddingTop: 0,
    },

    '&:last-child': {
      paddingBottom: 0,
    },

    '& + &': {
      borderTop: '1px solid rgba(249, 236, 49, 0.4)',
    },
  },
  image: {
    width: 60,
    height: 60,
    background: 'no-repeat center',
    backgroundSize: 'contain',
  },
  right: {
    paddingLeft: 20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '17px',
    textTransform: 'uppercase',
    marginBottom: 7,
  },
  description: {
    color: 'white',
    fontSize: 17,
    lineHeight: '18px',
  },
});

export const Rank = memo(function Rank(props: Props) {
  const {title, imageUrl, experience} = props;
  const mc = useStyles(props);
  const expPostfix = incline(experience, 'очко', 'очка', 'очков');

  return (
    <div className={mc.root}>
      <div
        className={mc.image}
        style={{backgroundImage: `url(${imageUrl})`}}
      />
      <div className={mc.right}>
        <Typography
          className={mc.title}
          fontFamily={'helvetica'}
          color={'primary'}
        >
          {title}
        </Typography>
        <Typography className={mc.description}>
          для получения ранга нужно {experience} {expPostfix}
        </Typography>
      </div>
    </div>
  );
});
