import React, {ChangeEvent, memo} from 'react';

import {ProfileLine} from '../Profile/ProfileLine';
import {ProfileLineTitle} from '../Profile/ProfileLineTitle';
import {Select} from 'vkma-ui';

import {withStyles} from '@material-ui/styles';

import {IdTitlePair} from '../panels/TeamPanel/types';
import {Theme} from '../../theme';

interface Props {
  title: string;
  placeholder: string;
  value?: string;
  values: IdTitlePair[];
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

const StyledSelect = withStyles((theme: Theme) => ({
  root: {
    border: 'none',
    padding: 0,
  },
}))(Select);

export const LineSelect = memo(function LineSelect(props: Props) {
  const {title, value, onChange, values, placeholder} = props;

  return (
    <ProfileLine>
      <ProfileLineTitle>{title}</ProfileLineTitle>
      <StyledSelect
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      >
        {values.map(({id, title}) => (
          <option value={id} key={id}>{title}</option>
        ))}
      </StyledSelect>
    </ProfileLine>
  );
});
