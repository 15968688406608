import TextareaAutosize from 'react-textarea-autosize';

import {styled} from '@material-ui/styles';

import {Theme} from '../../theme';

export const Textarea = styled<any>(TextareaAutosize)<Theme>(({theme}) => ({
  '-webkit-appearance': 'none',
  width: '100%',
  backgroundColor: theme.components.FormControl.colors.background,
  borderColor: theme.components.FormControl.colors.border,
  color: theme.components.Input.colors.foreground,
  fontSize: 16,
  fontFamily: theme.global.text.fontFamily,
  padding: 0,

  '&:focus, &:active': {
    outline: 'none',
  },

  '&:focus': {
    borderColor: theme.components.FormControl.colors.borderFocused,
  },

  '&::placeholder': {
    color: theme.components.Input.colors.placeholder,
  },
}));
