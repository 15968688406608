import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import {makeStyles} from '@material-ui/styles';
import {Theme} from '../../theme';

interface Props extends HTMLProps<HTMLDivElement> {
  fontFamily?: 'iris' | 'helvetica' | 'marsh' | string;
  color?: 'primary' | 'secondary' | string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    fontFamily: ({fontFamily}) => fontFamily === 'helvetica'
      ? 'HelveticaNeueCyr'
      : (fontFamily === 'marsh'
        ? 'Marsh Stencil'
        : (fontFamily === 'iris' || typeof fontFamily === 'undefined'
          ? 'Iris'
          : fontFamily)),
    color: ({color}) => color === 'primary'
      ? theme.palette.primary
      : (color === 'secondary' ? theme.palette.secondary : color),
  },
}));

export const Typography = memo(function Typography(props: Props) {
  const {className, fontFamily, ...rest} = props;
  const mc = useStyles(props);
  const rootClassName = c(className, mc.root);

  return <div className={rootClassName} {...rest}/>;
});
