import React, {
  memo,
  ReactNode,
  ReactNodeArray,
  useEffect,
  useMemo,
} from 'react';

import {DefaultPanelHeader} from '../../DefaultPanelHeader';
import {Typography} from '../../Typography';
import {PanelBody} from '../../PanelBody';
import {Loader} from '../../Loader';

import {makeStyles} from '@material-ui/styles';
import {useQuery} from '@apollo/react-hooks';
import {useActions, useSelector} from '../../../hooks';
import {ranksActions} from '../../../redux/ranks';

import {FetchRanksDocument, FetchRanksQuery} from '../../../types';

import backImage from '../../../assets/main-head-back.png';
import {Rank} from './Rank';

const useStyles = makeStyles({
  head: {
    background: `url(${backImage}) no-repeat center`,
    backgroundSize: 'cover',
    padding: '30px 20px',
    color: 'white',
  },
  headTitle: {
    fontSize: 29,
    lineHeight: '29px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  headSubtitle: {
    fontSize: 17,
    lineHeight: '19px',
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const RanksPanel = memo(function RanksPanel() {
  const mc = useStyles({});
  const ranks = useSelector(state => state.ranks.ranks);
  const setRanks = useActions(ranksActions.setRanks);

  const {data, loading} = useQuery<FetchRanksQuery>(FetchRanksDocument, {
    fetchPolicy: 'network-only',
  });

  // Update internal ranks when data fetched
  useEffect(() => {
    if (data) {
      setRanks(data.ranks);
    }
  }, [data, setRanks]);

  const sortedRanks = useMemo(() => {
    if (!ranks) {
      return null;
    }
    return ranks
      .filter(r => r.experience > 0)
      .sort((a, b) => a.experience - b.experience);
  }, [ranks]);

  let content: ReactNode | ReactNodeArray = null;

  if (!sortedRanks && loading) {
    content = (
      <div className={mc.loaderWrapper}>
        <Loader/>
      </div>
    );
  } else if (sortedRanks) {
    content = sortedRanks.map((r, idx) => <Rank {...r} key={idx}/>);
  } else {
    content = (
      <div>
        Err
      </div>
    );
  }

  return (
    <div>
      <DefaultPanelHeader/>
      <div className={mc.head}>
        <Typography className={mc.headTitle} fontFamily={'helvetica'}>
          РАНГИ
        </Typography>
        <Typography className={mc.headSubtitle} fontFamily={'helvetica'}>
          Прокачивайся в разделе достижений, повышай свой ранг и получай
          подарочные промокоды
        </Typography>
      </div>
      <PanelBody>
        {content}
      </PanelBody>
    </div>
  );
});
