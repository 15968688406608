import {ofType, unionize, UnionOf} from 'unionize';
import {
  FetchAchievementsAndPromoCodesQuery,
  FetchTeamQuery,
} from '../types';

export const apolloActions = unionize({
  setAchievementsAndPromoCodes: ofType<FetchAchievementsAndPromoCodesQuery>(),
  setTeamQuery: ofType<FetchTeamQuery>(),
}, {
  tag: 'type',
  value: 'payload',
} as const);

export interface ApolloReducerState {
  achievementsAndPromoCodes?: FetchAchievementsAndPromoCodesQuery;
  teamQuery?: FetchTeamQuery;
}

export type ApolloAction = UnionOf<typeof apolloActions>;

const initState: ApolloReducerState = {
};

export const apolloReducer = (
  state = initState,
  action: ApolloAction,
): ApolloReducerState => {
  return apolloActions.match(action, {
    setAchievementsAndPromoCodes: data => ({
      ...state,
      achievementsAndPromoCodes: data,
    }),
    setTeamQuery: data => ({...state, teamQuery: data}),
    default: () => state,
  });
};
