import {ofType, unionize, UnionOf} from 'unionize';

export interface Teammate {
  name: string;
  profileImageUrl: string;
  rankName?: string;
  vkUserId: number;
}

export interface TeamReducerState {
  users: Teammate[];
}

export const teamActions = unionize({
  setTeammates: ofType<Teammate[]>(),
  addTeammate: ofType<Teammate>(),
  removeTeammate: ofType<number>(),
}, {
  tag: 'type',
  value: 'payload',
} as const);

export type TeamAction = UnionOf<typeof teamActions>;

const initState: TeamReducerState = {
  users: [],
};

export const teamReducer = (
  state = initState,
  action: TeamAction,
): TeamReducerState => {
  return teamActions.match(action, {
    default: () => state,
    addTeammate: user => ({...state, users: [...state.users, user]}),
    removeTeammate: vkUserId => ({
      ...state,
      users: state.users.filter(u => u.vkUserId !== vkUserId),
    }),
    setTeammates: users => ({...state, users}),
  });
};
