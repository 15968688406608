import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/styles";

import { PanelHeader } from "vkma-ui";
import { Avatar } from "../Avatar";
import { Typography } from "../Typography";
import { Button } from "../Button";
import { Progress } from "../Progress";
import { RouterLink } from "../RouterLink";
import { AppLoadingView } from "../views/AppLoadingView";
import { SharingSelection } from "../SharingSelection";

import logoImageUrl from "../../assets/head-logo.svg";
import backImageUrl from "../../assets/main-head-back.png";
import { ReactComponent as NoRankImage } from "../../assets/no-rank.svg";
import rogImageUrl from "../../assets/rog.png";
import { ReactComponent as IconShare } from "../../assets/icons/share.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

import { Theme } from "../../theme";
import {
  FetchMainDocument,
  FetchMainQuery,
  PanelsEnum,
  ViewsEnum,
} from "../../types";

import { useActions, useGetShortLink, useSelector } from "../../hooks";
import { shareStory, shareWallPost } from "../../utils";
import { useConfig } from "../ConfigProvider";
import vkBridge from "@vkontakte/vk-bridge";
import { useQuery } from "@apollo/react-hooks";
import { userActions } from "../../redux/user";

const StyledPanelHeader = withStyles({
  before: {
    display: "flex",
    alignItems: "center",
    padding: "4px 0 4px 12px",
    height: "100%",
    boxSizing: "border-box",
  },
})(PanelHeader);

const useStyles = makeStyles<Theme, {}>(
  (theme) => ({
    root: {
      height: "calc(100% - 1px)",
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
      overflow: "auto",
    },
    head: {
      background: `url(${backImageUrl}) no-repeat center`,
      backgroundSize: "cover",
      padding: "30px 20px",
      overflow: "hidden",
      flex: "0 0 auto",
      position: "relative",
    },
    ranksInfoIcon: {
      position: "absolute",
      top: 15,
      right: 20,
      color: theme.palette.primary,
      width: 23,
      height: 23,
    },
    personal: {
      display: "flex",
    },
    avatar: {
      flex: "0 0 auto",
    },
    personalRight: {
      flex: "1 0 0",
      padding: "20px 0 0 20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    name: {
      color: "white",
      textTransform: "uppercase",
      fontSize: 15,
      lineHeight: "15px",
      marginBottom: 17,
      fontWeight: "bold",
    },
    rank: {
      display: "flex",
      alignItems: "center",
    },
    rankImage: {
      background: "no-repeat center",
      backgroundSize: "contain",
      width: 35,
      height: 35,
      marginRight: 15,
    },
    noRankImage: {
      marginRight: 15,
      width: 35,
    },
    rankTitle: {
      color: "white",
      fontSize: 16,
      lineHeight: "14px",
    },
    progress: {
      marginTop: 30,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: 28,
    },
    body: {
      padding: "30px 20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "1 0 auto",
      backgroundColor: theme.palette.primary,
    },
    menu: {
      maxWidth: 250,
    },
    menuItem: {
      display: "flex",
      alignItems: "flex-start",
      color: "black",

      "& + &": {
        marginTop: 35,
      },
    },
    menuItemIndex: {
      fontSize: 14,
      flex: "0 0 35px",
    },
    menuItemTitle: {
      flex: "1 0 0",
      textTransform: "uppercase",
      fontSize: 20,
      lineHeight: "20px",
      fontWeight: "bold",
    },
    sharePopup: {
      position: "fixed",
      zIndex: 1000,
    },
    rogLogo: {
      marginLeft: 25,
      height: 36,
    },
  }),
  { name: "MainPanel" }
);

export const MainPanel = memo(function MainPanel() {
  const mc = useStyles({});
  const {
    name,
    experience,
    profileImageUrl,
    rank,
    nextRank,
    profile,
  } = useSelector((state) => state.user);
  const {
    launchParams: { appId },
  } = useConfig();
  const getShortLink = useGetShortLink();
  const setUserMainInfo = useActions(userActions.setUserMainInfo);
  const [isSharing, setIsSharing] = useState(false);
  const [showShareSelection, setShowShareSelection] = useState(false);
  const menuItems = useMemo(
    () => [
      { title: "Моя команда", panel: PanelsEnum.Team },
      { title: "Карта клубов", panel: PanelsEnum.ClubsMap },
      { title: "Регистрация чека", panel: PanelsEnum.RegisterCheck },
      { title: "Достижения и промокоды", panel: PanelsEnum.Achievements },
    ],
    []
  );

  // Shares rank via wall post
  const onShareWallClick = useCallback(async () => {
    if (rank) {
      setIsSharing(true);
      try {
        const link = `https://vk.com/app${appId}`;

        const shortLink = await getShortLink(link);

        await shareWallPost(
          `Докажи, что ты крутой геймер! Вступай в клуб: ${shortLink}`,
          link,
          rank.snippetImageUrl
        );
      } catch (e) {}
      setIsSharing(false);
    }
  }, [rank, appId, getShortLink]);

  // Shares rank via story
  const onShareStoryClick = useCallback(async () => {
    if (rank) {
      setIsSharing(true);
      try {
        await shareStory(`https://vk.com/app${appId}`, rank.storyImageUrl);
      } catch (e) {}
      setIsSharing(false);
    }
  }, [rank, appId]);

  // Opens share selection menu or shares via post in case, story is not
  // supported
  const onShareClick = useCallback(() => {
    if (vkBridge.supports("VKWebAppShowStoryBox")) {
      return setShowShareSelection(true);
    }
    return onShareWallClick();
  }, [onShareWallClick]);

  // Update main info
  const { data } = useQuery<FetchMainQuery>(FetchMainDocument, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data) {
      // Emit events for redux store
      const {
        profileImageUrl,
        experience,
        name,
        rank,
        nextRank,
        profile,
        notifications,
      } = data.user;
      setUserMainInfo({
        profileImageUrl,
        name,
        experience,
        rank,
        nextRank,
        notifications,
        profile: profile
          ? {
              age: profile.age,
              clubId: profile.clubId,
              gameId: profile.game.id,
              stageId: profile.stage.id,
              playTimeId: profile.playTime.id,
              about: profile.about,
            }
          : null,
      });
    }
  }, [data, setUserMainInfo]);

  // If name is empty, it means, user was not loaded before and we should
  // show loader. Not sure it is correct logic for doing this, but we got no
  // time to make it better
  if (name === "") {
    return <AppLoadingView />;
  }

  return (
    <>
      <div className={mc.root}>
        <StyledPanelHeader
          before={
            <>
              <img src={logoImageUrl} alt={""} />
              <img className={mc.rogLogo} src={rogImageUrl} alt={""} />
            </>
          }
        />
        <div className={mc.head}>
          <RouterLink to={{ view: ViewsEnum.Main, panel: PanelsEnum.Ranks }}>
            <InfoIcon className={mc.ranksInfoIcon} />
          </RouterLink>
          <div className={mc.personal}>
            <Avatar
              className={mc.avatar}
              imageUrl={profileImageUrl}
              size={110}
            />
            <div className={mc.personalRight}>
              <Typography className={mc.name} fontFamily={"helvetica"}>
                {name}
              </Typography>
              <div className={mc.rank}>
                {rank?.imageUrl ? (
                  <div
                    className={mc.rankImage}
                    style={{ backgroundImage: `url(${rank.imageUrl})` }}
                  />
                ) : (
                  <NoRankImage className={mc.noRankImage} />
                )}
                <Typography className={mc.rankTitle}>
                  Ранг: {rank?.title || "Неизвестно"}
                </Typography>
              </div>
            </div>
          </div>
          <div className={mc.progress}>
            <Progress
              fullProgressTitle={"Максимальный уровень"}
              current={experience}
              max={nextRank?.experience || 0}
            />
          </div>
          {rank && (
            <div className={mc.buttonWrapper}>
              <Button
                variant={"outline"}
                before={<IconShare />}
                size={"xl"}
                onClick={onShareClick}
                disabled={isSharing}
              >
                ПОДЕЛИТЬСЯ
              </Button>
            </div>
          )}
        </div>
        <div className={mc.body}>
          <div className={mc.menu}>
            {menuItems.map((item, idx) => {
              const { title } = item;
              const inner = (
                <div className={mc.menuItem} key={idx}>
                  <Typography className={mc.menuItemIndex} fontFamily={"marsh"}>
                    0{idx + 1}
                  </Typography>
                  <Typography
                    className={mc.menuItemTitle}
                    fontFamily={"helvetica"}
                  >
                    {title}
                  </Typography>
                </div>
              );

              if (typeof item.panel !== "undefined") {
                return (
                  <RouterLink
                    to={{ view: ViewsEnum.Main, panel: item.panel }}
                    key={idx}
                  >
                    {inner}
                  </RouterLink>
                );
              }

              return inner;
            })}
            {profile && (
              <RouterLink
                to={{
                  view: ViewsEnum.Main,
                  panel: PanelsEnum.Club,
                  query: { clubId: profile.clubId },
                }}
              >
                <div className={mc.menuItem}>
                  <Typography className={mc.menuItemIndex} fontFamily={"marsh"}>
                    0{menuItems.length + 1}
                  </Typography>
                  <Typography
                    className={mc.menuItemTitle}
                    fontFamily={"helvetica"}
                  >
                    Мой клуб
                  </Typography>
                </div>
              </RouterLink>
            )}
          </div>
        </div>
      </div>
      {showShareSelection && (
        <SharingSelection
          onClose={() => setShowShareSelection(false)}
          onStoryClick={onShareStoryClick}
          onWallClick={onShareWallClick}
        />
      )}
    </>
  );
});
