import {ofType, unionize, UnionOf} from 'unionize';
import {NotificationTypesEnum} from '../types';

interface Profile {
  age: number;
  clubId: string;
  gameId: string;
  stageId: string;
  playTimeId: string;
  about?: string | null;
}

export interface PromoCode {
  id: string;
  title: string;
  value?: string | null;
  expiresAt?: Date | null;
}

interface Notification {
  id: string;
  title: string;
  type?: NotificationTypesEnum | null;
}

interface MainInfo {
  profileImageUrl: string;
  name: string;
  rank?: {
    title: string;
    imageUrl: string;
    storyImageUrl: string;
    snippetImageUrl: string;
  } | null;
  nextRank?: {
    experience: number;
  } | null;
  experience: number;
  profile?: Profile | null;
  notifications: Notification[];
}

interface UserAchievement {
  id: string;
  progress: number;
}

export interface UserReducerState extends MainInfo {
  promoCodes?: PromoCode[];
  achievements?: UserAchievement[];
}

export const userActions = unionize({
  markNotificationAsRead: ofType<string>(),
  addNotification: ofType<Notification>(),
  setUserMainInfo: ofType<MainInfo>(),
  setUserAchievements: ofType<UserAchievement[]>(),
  increaseUserAchievementProgress: ofType<{ id: string; amount: number }>(),
  setProfile: ofType<Profile | null>(),
  setPromoCodes: ofType<PromoCode[]>(),
  addPromoCode: ofType<PromoCode>(),
  updatePromoCode: ofType<{ id: string; data: Partial<PromoCode> }>(),
  removePromoCode: ofType<string>(),
}, {
  tag: 'type',
  value: 'payload',
} as const);

export type UserAction = UnionOf<typeof userActions>;

const initState: UserReducerState = {
  profileImageUrl: '',
  name: '',
  experience: 0,
  notifications: [],
};

export const userReducer = (
  state = initState,
  action: UserAction,
): UserReducerState => {
  return userActions.match(action, {
    default: () => state,
    addNotification: notification => ({
      ...state,
      notifications: [...state.notifications, notification],
    }),
    addPromoCode: promoCode => ({
      ...state,
      promoCodes: state.promoCodes
        ? [...state.promoCodes, promoCode]
        : [promoCode],
    }),
    markNotificationAsRead: id => ({
      ...state,
      notifications: state.notifications.filter(n => n.id !== id),
    }),
    increaseUserAchievementProgress: ({id, amount}) => {
      let isFound = false;
      const achievements = state.achievements
        ? state.achievements.map(a => {
          if (a.id === id) {
            isFound = true;
            return {...a, progress: a.progress + amount};
          }
          return a;
        })
        : [];

      if (!isFound) {
        achievements.push({id, progress: amount});
      }

      return {...state, achievements};
    },
    setUserAchievements: achievements => ({...state, achievements}),
    setUserMainInfo: info => ({...state, ...info}),
    setProfile: profile => ({...state, profile}),
    setPromoCodes: promoCodes => ({...state, promoCodes}),
    updatePromoCode: ({id, data}) => ({
      ...state,
      promoCodes: state.promoCodes
        ? state.promoCodes.map(p => {
          if (p.id === id) {
            return {...p, ...data};
          }
          return p;
        })
        : state.promoCodes,
    }),
    removePromoCode: id => ({
      ...state,
      promoCodes: state.promoCodes
        ? state.promoCodes.filter(p => p.id !== id)
        : [],
    }),
  });
};
