import React, {memo} from 'react';
import c from 'classnames';

import {makeStyles} from '@material-ui/styles';

import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as Arrows} from '../../assets/logo-arrows.svg';

interface Props {
  className?: string;
  size?: number;
}

const useStyles = makeStyles<{}, Props>({
  root: {
    position: 'relative',
    width: ({size}) => size || 40,
    height: ({size}) => size || 40,
  },
  arrows: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    animation: '$spin infinite 1300ms linear',
  },
  logo: {
    height: '100%',
    width: '100%',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg) scale(1)',
    },
    '50%': {
      transform: 'rotate(180deg) scale(1.2)',
    },
    to: {
      transform: 'rotate(360deg) scale(1)',
    },
  },
}, {name: 'Loader'});

export const Loader = memo(function Loader(props: Props) {
  const {className} = props;
  const mc = useStyles(props);

  return (
    <div className={c(mc.root, className)}>
      <Arrows className={mc.arrows}/>
      <Logo className={mc.logo}/>
    </div>
  );
});
