/**
 * List of errors which can be sent from Apollo API
 */
export enum APIErrorsEnum {
  Authorization = 'Authorization',
  CheckActivationDayLimitReached = 'CheckActivationDayLimitReached',
  FullTeam = 'FullTeam',
  NotFound = 'NotFound',
  PromoNotFound = 'PromoNotFound',
  RankNotFound = 'RankNotFound',
  UserNotFound = 'UserNotFound',
  UserDeactivated = 'UserDeactivated',
  Unknown = 'Unknown',
}

/**
 * Description of errors sent from Apollo API
 */
export interface APIError<ExtraInfo = any> {
  message: string;
  graphQLErrors: ReadonlyArray<{
    message: string;
    name: APIErrorsEnum;
  }>;
  networkError: Error | null;
  extraInfo: ExtraInfo;
}
