import React, {memo, useCallback, useState} from 'react';
import c from 'classnames';

import {makeStyles, withStyles} from '@material-ui/styles';

import {Typography} from '../../Typography';
import {Button} from '../../Button';
import SwipeableViews from 'react-swipeable-views';

import {useRouter, useStorage} from '../../../hooks';
import {onboardingSlides} from './slides';
import vkBridge from '@vkontakte/vk-bridge';

import logoUrl from '../../../assets/onboarding-logo.svg';

import {PanelsEnum, StorageFieldEnum, ViewsEnum} from '../../../types';

const Dot = withStyles({
  root: {
    width: 7,
    height: 7,
    padding: 0,
    borderRadius: '50%',
    transition: '300ms opacity linear',
    opacity: 0.3,

    '& + &': {
      marginLeft: 11,
    },
  },
  content: {
    padding: 0,
  },
})(Button);

const useStyles = makeStyles({
  root: {
    height: 'calc(100% - 1px)',
    backgroundColor: 'black',
    position: 'relative',
  },
  gallery: {
    height: '100%',
    paddingBottom: 55,
    boxSizing: 'border-box',

    '& .react-swipeable-view-container': {
      height: '100%',
    },
  },
  slide: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
  head: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 20px',
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    marginTop: 50,
    textAlign: 'center',
    fontSize: 17,
    lineHeight: '22px',
    fontWeight: 'bold',
    maxWidth: 250,
  },
  button: {
    fontSize: 14,
  },
  buttonText: {
    letterSpacing: 0,
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    padding: 0,
  },
  dotActive: {
    opacity: 1,
  },
}, {name: 'OnboardingPanel'});

export const OnboardingPanel = memo(function OnboardingPanel() {
  const mc = useStyles({});
  const [slide, setSlide] = useState(0);
  const {memoize} = useStorage();
  const router = useRouter();
  const [isRequesting, setIsRequesting] = useState(false);

  const setNextSlide = useCallback(() => setSlide(slide => slide + 1), []);
  const setOnboardingCompleted = useCallback(async () => {
    // Request notifications
    setIsRequesting(true);
    try {
      await vkBridge.send('VKWebAppAllowNotifications');
    } catch (e) {
    }
    setIsRequesting(false);

    router.pushState({view: ViewsEnum.Main, panel: PanelsEnum.Main});
    return memoize(StorageFieldEnum.OnboardingCompleted, true);
  }, [memoize, router]);

  return (
    <div className={mc.root}>
      <SwipeableViews
        className={mc.gallery}
        axis={'x'}
        index={slide}
        onChangeIndex={setSlide}
      >
        {onboardingSlides.map(([title, buttonText], idx, arr) => {
          const onClick = idx === arr.length - 1
            ? setOnboardingCompleted
            : setNextSlide;

          return (
            <div className={mc.slide} key={idx}>
              <div className={mc.head}>
                <div className={mc.imageContainer}>
                  <img className={mc.image} src={logoUrl} alt={''}/>
                </div>
                <Typography
                  className={mc.title}
                  fontFamily={'helvetica'}
                  color={'primary'}
                >
                  {title}
                </Typography>
              </div>
              <Button
                className={mc.button}
                variant={'primary'}
                size={'xl'}
                disabled={isRequesting}
                onClick={onClick}
              >
                <Typography className={mc.buttonText} fontFamily={'iris'}>
                  {buttonText}
                </Typography>
              </Button>
            </div>
          );
        })}
      </SwipeableViews>
      <div className={mc.dots}>
        {new Array(onboardingSlides.length).fill(null).map((_, idx) => (
          <Dot
            variant={'primary'}
            className={c({[mc.dotActive]: slide === idx})}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
});
