import { ofType, unionize, UnionOf } from 'unionize';
import { TClub } from '../types/club';

export interface ClubsReducerState {
  clubs?: TClub[] | null;
}

export const clubsActions = unionize({
  addClub: ofType<TClub>(),
  setClubs: ofType<TClub[] | null>(),
}, {
  tag: 'type',
  value: 'payload',
} as const);

export type ClubsAction = UnionOf<typeof clubsActions>;

const initState: ClubsReducerState = {};

export const clubsReducer = (
  state = initState,
  action: ClubsAction,
): ClubsReducerState => {
  return clubsActions.match(action, {
    addClub: club => {
      if (!state.clubs) {
        return {...state, clubs: [club]};
      }

      let clubFound = false;
      const clubs = state.clubs.map(c => {
        if (c.id === club.id) {
          clubFound = true;
          return club;
        }
        return c;
      });

      if (!clubFound) {
        clubs.push(club);
      }

      return {...state, clubs};
    },
    setClubs: clubs => ({ ...state, clubs }),
    default: () => state,
  }) as ClubsReducerState;
};
