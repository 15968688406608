import {withStyles} from '@material-ui/styles';

import {Button as UIButton} from 'vkma-ui';

export const Button = withStyles({
  root: {
    borderRadius: 3,

    '&[disabled]': {
      backgroundColor: '#727272',
      borderColor: '#727272',
      color:'#1d1d1d',
    }
  },
  content: {
    fontFamily: 'Iris',
    textTransform: 'uppercase'
  },
})(UIButton);
