import React, {memo, ReactNode, ReactNodeArray, useEffect} from 'react';
import {createPortal} from 'react-dom';

import {makeStyles} from '@material-ui/styles';
import c from 'classnames';

import {Theme} from '../../theme';

import {ReactComponent as DismissIcon} from '../../assets/icons/dismiss.svg';

export interface ModalProps {
  className?: string;
  children?: ReactNode | ReactNodeArray;
  onClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 16px',
    zIndex: 10000,
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    animation: '$backdrop-appear 300ms ease-in-out',
  },
  modal: {
    backgroundColor: theme.palette.primary,
    borderRadius: 18,
    padding: '35px 20px 25px',
    position: 'relative',
    zIndex: 1000,
    maxWidth: 335,
    width: '100%',
    animation: '$modal-appear 300ms ease-in-out',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 15,
  },
  '@keyframes backdrop-appear': {
    from: {opacity: 0},
    to: {opacity: 1},
  },
  '@keyframes modal-appear': {
    from: {
      opacity: 0,
      transform: 'scale(0.95)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
}), {name: 'Modal'});

export const Modal = memo(function Modal(props: ModalProps) {
  const {children, onClose, className} = props;
  const mc = useStyles(props);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return createPortal(
    <div className={mc.root}>
      <div className={mc.backdrop} onClick={onClose}/>
      <div className={c(mc.modal, className)}>
        <div className={mc.close} onClick={onClose}>
          <DismissIcon className={mc.closeIcon}/>
        </div>
        {children}
      </div>
    </div>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('modal-root')!,
  );
});
