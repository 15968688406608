import {
  AchievementAbout,
  AchievementAboutVariableFormattingEnum,
  AchievementAboutVariablesEnum,
} from '../../../types';
import {incline} from '../../../utils';

export function formatAbout(
  about: AchievementAbout,
  progressLeft: number,
): string {
  const {template, variables} = about;

  if (variables.length === 0) {
    return template;
  }
  let result = template;
  const progressLeftVariable = variables.find(v => {
    return v.variable === AchievementAboutVariablesEnum.ProgressLeft;
  });

  if (progressLeftVariable) {
    const {formatOperations} = progressLeftVariable;
    let value = progressLeft.toString();
    const inclineOperation = formatOperations.find(o => {
      return o.operation === AchievementAboutVariableFormattingEnum.Incline;
    });

    if (inclineOperation) {
      const [ifOne, ifTwo, ifFive] = inclineOperation.values;
      value = value + ' ' + incline(progressLeft, ifOne, ifTwo, ifFive);
    }

    result = result.replace(
      new RegExp(`{${AchievementAboutVariablesEnum.ProgressLeft}}`),
      value,
    );
  }

  return result;
}
