import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  /** Returns all achievements */
  achievements: Array<Achievement>;
  /** Returns list of ranks */
  ranks: Array<Rank>;
  /** Returns available cities */
  cities: Array<City>;
  /** Returns common clubs info */
  clubsInfo: ClubsInfo;
  /** Returns list of clubs */
  clubs: Array<Club>;
  /** Returns info about club */
  club: Club;
  /** Returns available games */
  games: Array<Game>;
  /** Returns available stages */
  stages: Array<Stage>;
  /** Returns available play times */
  playTimes: Array<PlayTime>;
  /** Returns compatible to parameters profiles */
  profiles: Array<Profile>;
  /** Returns user profile */
  profile?: Maybe<Profile>;
  /** Returns current user */
  user: User;
  /** Returns short link */
  getShortLink: Scalars['String'];
};


export type QueryClubArgs = {
  clubId: Scalars['ObjectId'];
};


export type QueryProfilesArgs = {
  gameId: Scalars['ObjectId'];
  clubId: Scalars['ObjectId'];
};


export type QueryProfileArgs = {
  vkUserId: Scalars['Int'];
};


export type QueryGetShortLinkArgs = {
  link: Scalars['String'];
};

/** Achievement */
export type Achievement = {
  __typename?: 'Achievement';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
  /** Achievement type */
  type: AchievementsEnum;
  /** Image URL */
  imageUrl: Scalars['String'];
  /** Description */
  description: Scalars['String'];
  /** About */
  about: AchievementAbout;
  /** Achievement levels */
  levels: Array<AchievementLevel>;
};


/** List of existing achievements */
export enum AchievementsEnum {
  TeamPlayer = 'TEAM_PLAYER',
  Habitue = 'HABITUE',
  LivingFullLife = 'LIVING_FULL_LIFE',
  Warrior = 'WARRIOR',
  Checkonator = 'CHECKONATOR'
}

/** Achievement about text */
export type AchievementAbout = {
  __typename?: 'AchievementAbout';
  /** Hint template */
  template: Scalars['String'];
  /** Variables used in template */
  variables: Array<AboutVariable>;
};

/** Achievement about variable */
export type AboutVariable = {
  __typename?: 'AboutVariable';
  /** Variable */
  variable: AchievementAboutVariablesEnum;
  /** List of variable formatting operations */
  formatOperations: Array<FormatOperationsUnion>;
};

/** List of variables which could be used while creating "about" information of achievement */
export enum AchievementAboutVariablesEnum {
  ProgressLeft = 'PROGRESS_LEFT'
}

export type FormatOperationsUnion = InclineFormatOperation;

/** Incline formatting operation */
export type InclineFormatOperation = {
  __typename?: 'InclineFormatOperation';
  /** Operation */
  operation: AchievementAboutVariableFormattingEnum;
  /** List of values */
  values: Array<Scalars['String']>;
};

/** List of formatting operations which could be applied to variable */
export enum AchievementAboutVariableFormattingEnum {
  Incline = 'INCLINE'
}

/** Achievement level */
export type AchievementLevel = {
  __typename?: 'AchievementLevel';
  /** Points required to get this level */
  points: Scalars['Float'];
  /** Snippet image URL */
  snippetImageUrl: Scalars['String'];
  /** Story image URL */
  storyImageUrl: Scalars['String'];
};

/** Rank */
export type Rank = {
  __typename?: 'Rank';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
  /** Reference to image */
  imageUrl: Scalars['String'];
  /** Experience required to reach rank */
  experience: Scalars['Int'];
  /** Reference to get sharing snippet image */
  snippetImageUrl: Scalars['String'];
  /** Reference to get sharing story image */
  storyImageUrl: Scalars['String'];
};

/** City of presence */
export type City = {
  __typename?: 'City';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
};

/** Common clubs data */
export type ClubsInfo = {
  __typename?: 'ClubsInfo';
  /** Count of opened clubs */
  opened: Scalars['Int'];
  /** Count of clubs which will be opened soon */
  openingSoon: Scalars['Int'];
  /** Clubs list */
  clubs: Array<Club>;
};

/** Club */
export type Club = {
  __typename?: 'Club';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
  /** Display description */
  description?: Maybe<Scalars['String']>;
  /** City where club is placed */
  city: City;
  /** Full address */
  address?: Maybe<Scalars['String']>;
  /** Club coordinates */
  coords?: Maybe<Coords>;
  /** Links to get club images */
  imageUrls?: Maybe<Array<Scalars['String']>>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** List of club's tariffs */
  tariffs: Scalars['String'];
  /** Hash tag */
  hashTag: Scalars['String'];
  socialUrls?: Maybe<SocialUrls>;
};

/** Coordinates */
export type Coords = {
  __typename?: 'Coords';
  /** Latitude */
  lat: Scalars['Float'];
  /** Latitude */
  lng: Scalars['Float'];
};

/** Map of existing links to social nets */
export type SocialUrls = {
  __typename?: 'SocialUrls';
  /** VK */
  vk: Scalars['String'];
};

/** Game */
export type Game = {
  __typename?: 'Game';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
};

/** Stage */
export type Stage = {
  __typename?: 'Stage';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
};

/** Play time */
export type PlayTime = {
  __typename?: 'PlayTime';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
};

/** User's profile */
export type Profile = {
  __typename?: 'Profile';
  /** Profile owner */
  user: User;
  /** User age */
  age: Scalars['Int'];
  clubId: Scalars['String'];
  /** Selected game */
  game: Game;
  /** Selected stage */
  stage: Stage;
  /** Selected play time */
  playTime: PlayTime;
  /** About user */
  about?: Maybe<Scalars['String']>;
};

/** User */
export type User = {
  __typename?: 'User';
  /** VK user identifier */
  vkUserId: Scalars['Int'];
  /** Full user's name */
  name: Scalars['String'];
  /** Avatar image url */
  profileImageUrl: Scalars['String'];
  /** Current user rank */
  rank?: Maybe<Rank>;
  /** Next reachable rank */
  nextRank?: Maybe<Rank>;
  /** Current experience */
  experience: Scalars['Int'];
  /** Profile */
  profile?: Maybe<Profile>;
  /** User current team */
  team?: Maybe<Team>;
  /** List of user promo codes */
  promoCodes: Array<UserPromoCode>;
  /** User achievements progress */
  achievements: Array<UserAchievement>;
  /** Unread notifications */
  notifications: Array<Notification>;
};

/** Team of users */
export type Team = {
  __typename?: 'Team';
  /** List of users */
  users: Array<User>;
};

/** User promo code */
export type UserPromoCode = {
  __typename?: 'UserPromoCode';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
  /** Type */
  type?: Maybe<PromoCodesEnum>;
  /** Unique promo code value */
  value?: Maybe<Scalars['String']>;
  /** Date when, promo code expires */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Date when promo code was created */
  createdAt: Scalars['DateTime'];
};

/** List of promo code types which could be used while creating new public promo code */
export enum PromoCodesEnum {
  Welcome = 'WELCOME',
  LotteryWinner = 'LOTTERY_WINNER'
}


export type UserAchievement = {
  __typename?: 'UserAchievement';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Display title */
  title: Scalars['String'];
  /** Achievement type */
  type: AchievementsEnum;
  /** Image URL */
  imageUrl: Scalars['String'];
  /** Description */
  description: Scalars['String'];
  /** About */
  about: AchievementAbout;
  /** Achievement levels */
  levels: Array<AchievementLevel>;
  /** Achievement progress */
  progress: Scalars['Float'];
};

/** Notification sent to user */
export type Notification = {
  __typename?: 'Notification';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Notification text */
  title: Scalars['String'];
  /** Notification type */
  type?: Maybe<NotificationTypesEnum>;
};

/** List of notification types which could be used while creating notifications */
export enum NotificationTypesEnum {
  WelcomePromoCode = 'WELCOME_PROMO_CODE',
  PromoCodeReceived = 'PROMO_CODE_RECEIVED',
  UserJoinedTeam = 'USER_JOINED_TEAM',
  LotteryWon = 'LOTTERY_WON'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates initial achievements */
  createInitialAchievements: Scalars['Boolean'];
  /** Creates initial ranks */
  createInitialRanks: Scalars['Boolean'];
  /** Launches lottery */
  launchLottery: Lottery;
  /** Synchronizes list of clubs and cities with Colizeum API */
  synchronizeWithAPI: Scalars['Boolean'];
  /** Creates initial games */
  createInitialGames: Scalars['Boolean'];
  /** Creates initial stages */
  createInitialStages: Scalars['Boolean'];
  /** Creates initial play times */
  createInitialPlayTimes: Scalars['Boolean'];
  /** Drops current user profile */
  dropProfile: Scalars['Boolean'];
  /** Creates profile */
  createProfile: Profile;
  /** Joins user team */
  joinTeam: Team;
  /** Leaves team */
  leaveTeam: Scalars['Boolean'];
  /** Registers user */
  register: Scalars['Boolean'];
  /** Activates user promo code */
  activatePromoCode: UserPromoCode;
  /** Emits community subscription event */
  emitCommunitySubscriptionEvent: Scalars['Boolean'];
  /** Emits application visited event */
  emitAppVisitedEvent: Scalars['Boolean'];
  /** Marks notification as read */
  markNotificationAsRead: Scalars['Boolean'];
  /** Tries to activate check */
  activateCheck: Scalars['Boolean'];
};


export type MutationCreateProfileArgs = {
  age: Scalars['Int'];
  clubId: Scalars['ObjectId'];
  gameId: Scalars['ObjectId'];
  playTimeId: Scalars['ObjectId'];
  stageId: Scalars['ObjectId'];
  about?: Maybe<Scalars['String']>;
};


export type MutationJoinTeamArgs = {
  vkUserId: Scalars['Int'];
};


export type MutationActivatePromoCodeArgs = {
  promoCodeId: Scalars['ObjectId'];
};


export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['ObjectId'];
};


export type MutationActivateCheckArgs = {
  value: Scalars['String'];
};

/** Some lottery */
export type Lottery = {
  __typename?: 'Lottery';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Check which won lottery */
  winnerCheck: Check;
  /** Date when lottery was launched */
  launchedAt: Scalars['DateTime'];
};

export type Check = {
  __typename?: 'Check';
  /** Unique identifier */
  id: Scalars['ObjectId'];
  /** Check owner */
  owner: User;
  /** Check value */
  value: Scalars['String'];
  /** Date when check was activated */
  activatedAt: Scalars['DateTime'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Returns achievementId where progress was gained */
  onAchievementProgressGained: AchievementProgressGainedEvent;
  /** Subscribes for event when user leaves team */
  onUserLeftTeam: Scalars['Int'];
  /** Subscribes for event when user joins team */
  onUserJoinedTeam: User;
  /** Subscribes for new notifications */
  onNewNotifications: Array<Notification>;
  /** Subscribes for new promo codes */
  onNewPromoCodes: Array<UserPromoCode>;
};

export type AchievementProgressGainedEvent = {
  __typename?: 'AchievementProgressGainedEvent';
  /** Achievement identifier */
  achievementId: Scalars['ObjectId'];
  /** Gained progres amount */
  amount: Scalars['Float'];
};

export type FullProfileFragmentFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'age' | 'about' | 'clubId'>
  & { game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'title'>
  ), stage: (
    { __typename?: 'Stage' }
    & Pick<Stage, 'id' | 'title'>
  ), playTime: (
    { __typename?: 'PlayTime' }
    & Pick<PlayTime, 'id' | 'title'>
  ) }
);

export type RegisterMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type CreateProfileMutationVariables = Exact<{
  age: Scalars['Int'];
  gameId: Scalars['ObjectId'];
  clubId: Scalars['ObjectId'];
  playTimeId: Scalars['ObjectId'];
  stageId: Scalars['ObjectId'];
  about?: Maybe<Scalars['String']>;
}>;


export type CreateProfileMutation = (
  { __typename?: 'Mutation' }
  & { createProfile: (
    { __typename?: 'Profile' }
    & FullProfileFragmentFragment
  ) }
);

export type DropProfileMutationVariables = Exact<{ [key: string]: never; }>;


export type DropProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dropProfile'>
);

export type ActivateCheckMutationVariables = Exact<{
  value: Scalars['String'];
}>;


export type ActivateCheckMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateCheck'>
);

export type EmitCommunitySubscriptionEventMutationVariables = Exact<{ [key: string]: never; }>;


export type EmitCommunitySubscriptionEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emitCommunitySubscriptionEvent'>
);

export type EmitAppVisitedEventMutationVariables = Exact<{ [key: string]: never; }>;


export type EmitAppVisitedEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emitAppVisitedEvent'>
);

export type JoinTeamMutationVariables = Exact<{
  vkUserId: Scalars['Int'];
}>;


export type JoinTeamMutation = (
  { __typename?: 'Mutation' }
  & { joinTeam: (
    { __typename?: 'Team' }
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'vkUserId' | 'profileImageUrl' | 'name'>
      & { rank?: Maybe<(
        { __typename?: 'Rank' }
        & Pick<Rank, 'title'>
      )> }
    )> }
  ) }
);

export type LeaveTeamMutationVariables = Exact<{ [key: string]: never; }>;


export type LeaveTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leaveTeam'>
);

export type ActivatePromoMutationVariables = Exact<{
  promoCodeId: Scalars['ObjectId'];
}>;


export type ActivatePromoMutation = (
  { __typename?: 'Mutation' }
  & { activatePromoCode: (
    { __typename?: 'UserPromoCode' }
    & Pick<UserPromoCode, 'value' | 'expiresAt'>
  ) }
);

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationId: Scalars['ObjectId'];
}>;


export type MarkNotificationAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markNotificationAsRead'>
);

export type UserPromoCodesFragmentFragment = (
  { __typename?: 'User' }
  & { promoCodes: Array<(
    { __typename?: 'UserPromoCode' }
    & Pick<UserPromoCode, 'id' | 'title' | 'value' | 'expiresAt'>
  )> }
);

export type UserAchievementsFragmentFragment = (
  { __typename?: 'User' }
  & { achievements: Array<(
    { __typename?: 'UserAchievement' }
    & Pick<UserAchievement, 'id' | 'progress'>
  )> }
);

export type FetchAchievementsAndPromoCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAchievementsAndPromoCodesQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserPromoCodesFragmentFragment
    & UserAchievementsFragmentFragment
  ), achievements: Array<(
    { __typename?: 'Achievement' }
    & Pick<Achievement, 'id' | 'title' | 'description' | 'type' | 'imageUrl'>
    & { about: (
      { __typename?: 'AchievementAbout' }
      & Pick<AchievementAbout, 'template'>
      & { variables: Array<(
        { __typename?: 'AboutVariable' }
        & Pick<AboutVariable, 'variable'>
        & { formatOperations: Array<(
          { __typename?: 'InclineFormatOperation' }
          & Pick<InclineFormatOperation, 'operation' | 'values'>
        )> }
      )> }
    ), levels: Array<(
      { __typename?: 'AchievementLevel' }
      & Pick<AchievementLevel, 'points' | 'snippetImageUrl' | 'storyImageUrl'>
    )> }
  )> }
);

export type FetchMainQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchMainQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'profileImageUrl' | 'experience'>
    & { rank?: Maybe<(
      { __typename?: 'Rank' }
      & Pick<Rank, 'title' | 'imageUrl' | 'snippetImageUrl' | 'storyImageUrl'>
    )>, nextRank?: Maybe<(
      { __typename?: 'Rank' }
      & Pick<Rank, 'experience'>
    )>, notifications: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'title' | 'type'>
    )>, profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'age' | 'about' | 'clubId'>
      & { game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id'>
      ), stage: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id'>
      ), playTime: (
        { __typename?: 'PlayTime' }
        & Pick<PlayTime, 'id'>
      ) }
    )> }
  ) }
);

export type FetchTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchTeamQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'profileImageUrl'>
    & { rank?: Maybe<(
      { __typename?: 'Rank' }
      & Pick<Rank, 'title'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'vkUserId' | 'name' | 'profileImageUrl'>
        & { rank?: Maybe<(
          { __typename?: 'Rank' }
          & Pick<Rank, 'title'>
        )> }
      )> }
    )>, profile?: Maybe<(
      { __typename?: 'Profile' }
      & FullProfileFragmentFragment
    )> }
  ), clubs: Array<(
    { __typename?: 'Club' }
    & Pick<Club, 'id' | 'title' | 'address'>
    & { city: (
      { __typename?: 'City' }
      & Pick<City, 'id'>
    ) }
  )>, cities: Array<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'title'>
  )>, games: Array<(
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'title'>
  )>, stages: Array<(
    { __typename?: 'Stage' }
    & Pick<Stage, 'id' | 'title'>
  )>, playTimes: Array<(
    { __typename?: 'PlayTime' }
    & Pick<PlayTime, 'id' | 'title'>
  )> }
);

export type SearchProfilesQueryVariables = Exact<{
  gameId: Scalars['ObjectId'];
  clubId: Scalars['ObjectId'];
}>;


export type SearchProfilesQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'vkUserId'>
  ), profiles: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'age' | 'about'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'vkUserId' | 'name' | 'profileImageUrl'>
      & { rank?: Maybe<(
        { __typename?: 'Rank' }
        & Pick<Rank, 'title'>
      )> }
    ), stage: (
      { __typename?: 'Stage' }
      & Pick<Stage, 'title'>
    ), playTime: (
      { __typename?: 'PlayTime' }
      & Pick<PlayTime, 'title'>
    ) }
  )> }
);

export type FetchUserProfileQueryVariables = Exact<{
  vkUserId: Scalars['Int'];
}>;


export type FetchUserProfileQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'age' | 'about'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'vkUserId' | 'profileImageUrl' | 'name'>
      & { rank?: Maybe<(
        { __typename?: 'Rank' }
        & Pick<Rank, 'title'>
      )> }
    ), club: (
      { __typename?: 'Club' }
      & Pick<Club, 'title'>
      & { city: (
        { __typename?: 'City' }
        & Pick<City, 'title'>
      ) }
    ), game: (
      { __typename?: 'Game' }
      & Pick<Game, 'title'>
    ), stage: (
      { __typename?: 'Stage' }
      & Pick<Stage, 'title'>
    ), playTime: (
      { __typename?: 'PlayTime' }
      & Pick<PlayTime, 'title'>
    ) }
  )> }
);

export type FetchClubsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchClubsQuery = (
  { __typename?: 'Query' }
  & { clubsInfo: (
    { __typename?: 'ClubsInfo' }
    & Pick<ClubsInfo, 'opened' | 'openingSoon'>
    & { clubs: Array<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'title' | 'description' | 'address' | 'hashTag' | 'imageUrls'>
      & { socialUrls?: Maybe<(
        { __typename?: 'SocialUrls' }
        & Pick<SocialUrls, 'vk'>
      )>, coords?: Maybe<(
        { __typename?: 'Coords' }
        & Pick<Coords, 'lat' | 'lng'>
      )>, city: (
        { __typename?: 'City' }
        & Pick<City, 'id' | 'title'>
      ) }
    )> }
  ), cities: Array<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'title'>
  )> }
);

export type FetchClubQueryVariables = Exact<{
  clubId: Scalars['ObjectId'];
}>;


export type FetchClubQuery = (
  { __typename?: 'Query' }
  & { club: (
    { __typename?: 'Club' }
    & Pick<Club, 'id' | 'title' | 'description' | 'address' | 'hashTag' | 'imageUrls' | 'tariffs'>
    & { socialUrls?: Maybe<(
      { __typename?: 'SocialUrls' }
      & Pick<SocialUrls, 'vk'>
    )>, coords?: Maybe<(
      { __typename?: 'Coords' }
      & Pick<Coords, 'lat' | 'lng'>
    )>, city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'title'>
    ) }
  ) }
);

export type FetchRanksQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchRanksQuery = (
  { __typename?: 'Query' }
  & { ranks: Array<(
    { __typename?: 'Rank' }
    & Pick<Rank, 'title' | 'experience' | 'imageUrl'>
  )> }
);

export type FetchShortLinkQueryVariables = Exact<{
  link: Scalars['String'];
}>;


export type FetchShortLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getShortLink'>
);

export type SubscribeNewNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeNewNotificationsSubscription = (
  { __typename?: 'Subscription' }
  & { onNewNotifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'title' | 'type'>
  )> }
);

export type SubscribeAchievementProgressGainSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeAchievementProgressGainSubscription = (
  { __typename?: 'Subscription' }
  & { onAchievementProgressGained: (
    { __typename?: 'AchievementProgressGainedEvent' }
    & Pick<AchievementProgressGainedEvent, 'achievementId' | 'amount'>
  ) }
);

export type SubscribeNewPromosSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeNewPromosSubscription = (
  { __typename?: 'Subscription' }
  & { onNewPromoCodes: Array<(
    { __typename?: 'UserPromoCode' }
    & Pick<UserPromoCode, 'id' | 'title' | 'value' | 'expiresAt'>
  )> }
);

export type SubscribeUserLeftTeamSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeUserLeftTeamSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'onUserLeftTeam'>
);

export type SubscribeUserJoinedTeamSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeUserJoinedTeamSubscription = (
  { __typename?: 'Subscription' }
  & { onUserJoinedTeam: (
    { __typename?: 'User' }
    & Pick<User, 'vkUserId' | 'name' | 'profileImageUrl'>
    & { rank?: Maybe<(
      { __typename?: 'Rank' }
      & Pick<Rank, 'title'>
    )> }
  ) }
);

export const FullProfileFragmentFragmentDoc = gql`
    fragment FullProfileFragment on Profile {
  age
  clubId
  game {
    id
    title
  }
  stage {
    id
    title
  }
  playTime {
    id
    title
  }
  about
}
    `;
export const UserPromoCodesFragmentFragmentDoc = gql`
    fragment UserPromoCodesFragment on User {
  promoCodes {
    id
    title
    value
    expiresAt
  }
}
    `;
export const UserAchievementsFragmentFragmentDoc = gql`
    fragment UserAchievementsFragment on User {
  achievements {
    id
    progress
  }
}
    `;
export const RegisterDocument = gql`
    mutation register {
  register
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const CreateProfileDocument = gql`
    mutation CreateProfile($age: Int!, $gameId: ObjectId!, $clubId: String!, $playTimeId: ObjectId!, $stageId: ObjectId!, $about: String) {
  createProfile(age: $age, gameId: $gameId, clubId: $clubId, playTimeId: $playTimeId, stageId: $stageId, about: $about) {
    ...FullProfileFragment
  }
}
    ${FullProfileFragmentFragmentDoc}`;
export type CreateProfileMutationFn = ApolloReactCommon.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;
export type CreateProfileMutationResult = ApolloReactCommon.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const DropProfileDocument = gql`
    mutation DropProfile {
  dropProfile
}
    `;
export type DropProfileMutationFn = ApolloReactCommon.MutationFunction<DropProfileMutation, DropProfileMutationVariables>;
export type DropProfileMutationResult = ApolloReactCommon.MutationResult<DropProfileMutation>;
export type DropProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<DropProfileMutation, DropProfileMutationVariables>;
export const ActivateCheckDocument = gql`
    mutation ActivateCheck($value: String!) {
  activateCheck(value: $value)
}
    `;
export type ActivateCheckMutationFn = ApolloReactCommon.MutationFunction<ActivateCheckMutation, ActivateCheckMutationVariables>;
export type ActivateCheckMutationResult = ApolloReactCommon.MutationResult<ActivateCheckMutation>;
export type ActivateCheckMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateCheckMutation, ActivateCheckMutationVariables>;
export const EmitCommunitySubscriptionEventDocument = gql`
    mutation EmitCommunitySubscriptionEvent {
  emitCommunitySubscriptionEvent
}
    `;
export type EmitCommunitySubscriptionEventMutationFn = ApolloReactCommon.MutationFunction<EmitCommunitySubscriptionEventMutation, EmitCommunitySubscriptionEventMutationVariables>;
export type EmitCommunitySubscriptionEventMutationResult = ApolloReactCommon.MutationResult<EmitCommunitySubscriptionEventMutation>;
export type EmitCommunitySubscriptionEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EmitCommunitySubscriptionEventMutation, EmitCommunitySubscriptionEventMutationVariables>;
export const EmitAppVisitedEventDocument = gql`
    mutation EmitAppVisitedEvent {
  emitAppVisitedEvent
}
    `;
export type EmitAppVisitedEventMutationFn = ApolloReactCommon.MutationFunction<EmitAppVisitedEventMutation, EmitAppVisitedEventMutationVariables>;
export type EmitAppVisitedEventMutationResult = ApolloReactCommon.MutationResult<EmitAppVisitedEventMutation>;
export type EmitAppVisitedEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EmitAppVisitedEventMutation, EmitAppVisitedEventMutationVariables>;
export const JoinTeamDocument = gql`
    mutation JoinTeam($vkUserId: Int!) {
  joinTeam(vkUserId: $vkUserId) {
    users {
      vkUserId
      profileImageUrl
      name
      rank {
        title
      }
    }
  }
}
    `;
export type JoinTeamMutationFn = ApolloReactCommon.MutationFunction<JoinTeamMutation, JoinTeamMutationVariables>;
export type JoinTeamMutationResult = ApolloReactCommon.MutationResult<JoinTeamMutation>;
export type JoinTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinTeamMutation, JoinTeamMutationVariables>;
export const LeaveTeamDocument = gql`
    mutation LeaveTeam {
  leaveTeam
}
    `;
export type LeaveTeamMutationFn = ApolloReactCommon.MutationFunction<LeaveTeamMutation, LeaveTeamMutationVariables>;
export type LeaveTeamMutationResult = ApolloReactCommon.MutationResult<LeaveTeamMutation>;
export type LeaveTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTeamMutation, LeaveTeamMutationVariables>;
export const ActivatePromoDocument = gql`
    mutation ActivatePromo($promoCodeId: ObjectId!) {
  activatePromoCode(promoCodeId: $promoCodeId) {
    value
    expiresAt
  }
}
    `;
export type ActivatePromoMutationFn = ApolloReactCommon.MutationFunction<ActivatePromoMutation, ActivatePromoMutationVariables>;
export type ActivatePromoMutationResult = ApolloReactCommon.MutationResult<ActivatePromoMutation>;
export type ActivatePromoMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivatePromoMutation, ActivatePromoMutationVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($notificationId: ObjectId!) {
  markNotificationAsRead(notificationId: $notificationId)
}
    `;
export type MarkNotificationAsReadMutationFn = ApolloReactCommon.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export type MarkNotificationAsReadMutationResult = ApolloReactCommon.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const FetchAchievementsAndPromoCodesDocument = gql`
    query FetchAchievementsAndPromoCodes {
  user {
    ...UserPromoCodesFragment
    ...UserAchievementsFragment
  }
  achievements {
    id
    title
    description
    type
    imageUrl
    about {
      template
      variables {
        variable
        formatOperations {
          ... on InclineFormatOperation {
            operation
            values
          }
        }
      }
    }
    levels {
      points
      snippetImageUrl
      storyImageUrl
    }
  }
}
    ${UserPromoCodesFragmentFragmentDoc}
${UserAchievementsFragmentFragmentDoc}`;
export type FetchAchievementsAndPromoCodesQueryResult = ApolloReactCommon.QueryResult<FetchAchievementsAndPromoCodesQuery, FetchAchievementsAndPromoCodesQueryVariables>;
export const FetchMainDocument = gql`
    query FetchMain {
  user {
    name
    profileImageUrl
    rank {
      title
      imageUrl
      snippetImageUrl
      storyImageUrl
    }
    nextRank {
      experience
    }
    notifications {
      id
      title
      type
    }
    experience
    profile {
      age
      clubId
      game {
        id
      }
      stage {
        id
      }
      playTime {
        id
      }
      about
    }
  }
}
    `;
export type FetchMainQueryResult = ApolloReactCommon.QueryResult<FetchMainQuery, FetchMainQueryVariables>;
export const FetchTeamDocument = gql`
    query FetchTeam {
  user {
    name
    profileImageUrl
    rank {
      title
    }
    team {
      users {
        vkUserId
        name
        profileImageUrl
        rank {
          title
        }
      }
    }
    profile {
      ...FullProfileFragment
    }
  }
  games {
    id
    title
  }
  stages {
    id
    title
  }
  playTimes {
    id
    title
  }
}
    ${FullProfileFragmentFragmentDoc}`;
export type FetchTeamQueryResult = ApolloReactCommon.QueryResult<FetchTeamQuery, FetchTeamQueryVariables>;
export const SearchProfilesDocument = gql`
    query SearchProfiles($gameId: ObjectId!, $clubId: String!) {
  user {
    vkUserId
  }
  profiles(gameId: $gameId, clubId: $clubId) {
    user {
      vkUserId
      name
      rank {
        title
      }
      profileImageUrl
    }
    age
    stage {
      title
    }
    playTime {
      title
    }
    about
  }
}
    `;
export type SearchProfilesQueryResult = ApolloReactCommon.QueryResult<SearchProfilesQuery, SearchProfilesQueryVariables>;
export const FetchUserProfileDocument = gql`
    query FetchUserProfile($vkUserId: Int!) {
  profile(vkUserId: $vkUserId) {
    user {
      vkUserId
      profileImageUrl
      name
      rank {
        title
      }
    }
    age
    club {
      title
      city {
        title
      }
    }
    game {
      title
    }
    stage {
      title
    }
    playTime {
      title
    }
    about
  }
}
    `;
export const FetchRanksDocument = gql`
    query FetchRanks {
  ranks {
    title
    experience
    imageUrl
  }
}
    `;
export type FetchRanksQueryResult = ApolloReactCommon.QueryResult<FetchRanksQuery, FetchRanksQueryVariables>;
export const FetchShortLinkDocument = gql`
    query FetchShortLink($link: String!) {
  getShortLink(link: $link)
}
    `;
export type FetchShortLinkQueryResult = ApolloReactCommon.QueryResult<FetchShortLinkQuery, FetchShortLinkQueryVariables>;
export const SubscribeNewNotificationsDocument = gql`
    subscription SubscribeNewNotifications {
  onNewNotifications {
    id
    title
    type
  }
}
    `;
export type SubscribeNewNotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<SubscribeNewNotificationsSubscription>;
export const SubscribeAchievementProgressGainDocument = gql`
    subscription SubscribeAchievementProgressGain {
  onAchievementProgressGained {
    achievementId
    amount
  }
}
    `;
export type SubscribeAchievementProgressGainSubscriptionResult = ApolloReactCommon.SubscriptionResult<SubscribeAchievementProgressGainSubscription>;
export const SubscribeNewPromosDocument = gql`
    subscription SubscribeNewPromos {
  onNewPromoCodes {
    id
    title
    value
    expiresAt
  }
}
    `;
export type SubscribeNewPromosSubscriptionResult = ApolloReactCommon.SubscriptionResult<SubscribeNewPromosSubscription>;
export const SubscribeUserLeftTeamDocument = gql`
    subscription SubscribeUserLeftTeam {
  onUserLeftTeam
}
    `;
export type SubscribeUserLeftTeamSubscriptionResult = ApolloReactCommon.SubscriptionResult<SubscribeUserLeftTeamSubscription>;
export const SubscribeUserJoinedTeamDocument = gql`
    subscription SubscribeUserJoinedTeam {
  onUserJoinedTeam {
    vkUserId
    name
    profileImageUrl
    rank {
      title
    }
  }
}
    `;
export type SubscribeUserJoinedTeamSubscriptionResult = ApolloReactCommon.SubscriptionResult<SubscribeUserJoinedTeamSubscription>;