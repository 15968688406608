import React, {memo} from 'react';

import {withStyles} from '@material-ui/styles';

import {RouterLink} from '../RouterLink';
import {PanelHeader, PanelHeaderProps} from 'vkma-ui';

import {ReactComponent as ArrowBack} from './arrow-back.svg';

const StyledPanelHeader = withStyles({
  before: {
    paddingLeft: 10,
  },
})(PanelHeader);

export const DefaultPanelHeader = memo(function (props: PanelHeaderProps) {
  const {children, before, ...rest} = props;

  return (
    <StyledPanelHeader
      before={
        <>
          <RouterLink pop={true}>
            <ArrowBack/>
          </RouterLink>
          {before}
        </>
      }
      {...rest}
    >
      {children}
    </StyledPanelHeader>
  );
});
