import React from 'react';
import { TSocialLink } from '../../../../../../types/club';

import { ReactComponent as VKIcon } from './icons/vk.svg';
import { ReactComponent as InstIcon } from './icons/inst.svg';
import { ReactComponent as InternetIcon } from './icons/internet.svg';

interface ShareIconProps {
  name: TSocialLink['icon'];
}

export const ShareIcon = ({ name }: ShareIconProps) => {
  switch (name) {
    case 'inst':
      return <InstIcon />;

    case 'vk':
      return <VKIcon />;

    default:
      return <InternetIcon />;
  }
};
