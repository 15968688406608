import vkBridge from '@vkontakte/vk-bridge';
import storyBase from '../assets/story-base.jpg';

/**
 * Shares current user rank with wall post
 * @param message
 * @param link
 * @param {string} snippetImageUrl
 * @returns {Promise<string extends AnyReceiveMethodName ? ReceiveData<string> : void>}
 */
export async function shareWallPost(
  message: string,
  link: string,
  snippetImageUrl: string,
) {
  if (vkBridge.supports('VKWebAppShowWallPostBox')) {
    return vkBridge.send('VKWebAppShowWallPostBox', {
      message,
      attachments: link,
      /* eslint-disable @typescript-eslint/ban-ts-ignore */
      // @ts-ignore
      link_button: 'open_url',
      // @ts-ignore
      link_title: 'Присоединяйся к Colizeum',
      // @ts-ignore
      link_image: snippetImageUrl,
      /* eslint-enable @typescript-eslint/ban-ts-ignore */
    });
  }
}

/**
 * Loads image with specified source
 * @param {string} src
 * @returns {HTMLImageElement}
 */
function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((res, rej) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onstalled = rej;
    image.onerror = rej;
    image.onload = () => res(image);
    image.src = src;
  });
}

/**
 * Shares current user rank with story
 * @param link
 * @param {string} imageUrl
 * @returns {Promise<void>}
 */
export async function shareStory(
  link: string,
  imageUrl: string,
) {
  const canvas = document.createElement('canvas');
  canvas.height = window.innerHeight;
  canvas.width = window.innerWidth;
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.beginPath();
    ctx.rect(0, 0, window.innerWidth, window.innerHeight);
    ctx.fillStyle = 'black';
    ctx.fill();

    const [baseImage, image] = await Promise.all([
      loadImage(storyBase),
      loadImage(imageUrl),
    ]);

    const scale = Math.max(
      window.innerWidth / baseImage.width,
      window.innerHeight / baseImage.height,
    );
    const height = baseImage.height * scale;
    const width = baseImage.width * scale;
    ctx.drawImage(
      baseImage,
      (window.innerHeight - height) / 2,
      (window.innerWidth - width) / 2,
      width,
      height,
    );

    await vkBridge.send(
      'VKWebAppShowStoryBox',
      {
        background_type: 'image',
        blob: canvas.toDataURL(),
        stickers: [{
          sticker_type: 'renderable',
          sticker: {
            url: imageUrl,
            content_type: 'image',
            can_delete: false,
            original_height: image.height,
            original_width: image.width,
            transform: {
              relation_width: 0.6,
            },
            clickable_zones: [{
              action_type: 'link',
              action: {
                link,
                tooltip_text_key: 'tooltip_open_default',
              },
              clickable_area: [
                {x: 0, y: 0},
                {x: image.width, y: 0},
                {x: image.width, y: image.height},
                {x: 0, y: image.height},
              ],
            }],
          },
        }],
      },
    );
  }
}
