import React, {memo, useCallback, useEffect, useState} from 'react';

import {Typography} from '../../Typography';
import {Button} from '../../Button';
import {Modal} from '../../Modal';

import {makeStyles} from '@material-ui/styles';
import {useActions} from '../../../hooks';
import {userActions} from '../../../redux/user';
import {useMutation} from '@apollo/react-hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ru';

import {Theme} from '../../../theme';
import {
  ActivatePromoDocument,
  ActivatePromoMutation,
  ActivatePromoMutationVariables,
  APIErrorsEnum,
} from '../../../types';
import {apiErrorIncludes, isAPIError} from '../../../utils';

dayjs.extend(utc);

interface Props {
  id: string;
  title: string;
  value?: string | null;
  expiresAt?: Date | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    padding: '0 7.5px',
    flex: '0 0 auto',
    boxSizing: 'border-box',

    '&:first-child': {
      paddingLeft: 20,
    },
    '&:last-child': {
      paddingRight: 20,
    },
  },
  item: {
    height: '100%',
    background: 'repeating-linear-gradient(135deg, black 0px, black 5px, ' +
      `transparent 5px, transparent 10px) ${theme.palette.primary}`,
    padding: 5,
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  itemInner: {
    height: '100%',
    padding: 15,
    borderRadius: 5,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  title: {
    flex: '1 0 auto',
    fontSize: 15,
    maxWidth: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    lineHeight: '20px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  expiresAt: {
    backgroundColor: 'white',
    fontSize: 14,
    lineHeight: '16px',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '100%',
    padding: '9px 15px',
    boxSizing: 'border-box',
    marginTop: 9,
    borderRadius: 3,
  },
  button: {
    borderColor: 'black',
    color: 'black',
    marginTop: 9,
  },
  promoValue: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 2,
  },
  promoHint: {
    fontSize: 16,
    marginTop: 10,
  },
}));

export const PromoCode = memo(function PromoCode(props: Props) {
  const {value, title, expiresAt, id} = props;
  const expiresAtDate = expiresAt
    ? dayjs.utc(expiresAt).local().locale('ru')
    : null;
  const mc = useStyles(props);
  const isOpened = typeof value === 'string';

  const [isModalShown, setIsModalShown] = useState(false);
  const [activatePromo, {loading, data, error}] =
    useMutation<ActivatePromoMutation, ActivatePromoMutationVariables>(
      ActivatePromoDocument,
    );

  const {updatePromoCode, removePromoCode} = useActions({
    updatePromoCode: userActions.updatePromoCode,
    removePromoCode: userActions.removePromoCode,
  });

  const onActivate = useCallback(async () => {
    try {
      await activatePromo({variables: {promoCodeId: id}});
    } catch (e) {
    }
  }, [activatePromo, id]);

  const onView = useCallback(() => {
    setIsModalShown(true);
  }, []);

  // Locally update promo code
  useEffect(() => {
    if (data) {
      (async () => {
        try {
          await updatePromoCode({id, data: data.activatePromoCode});
          setIsModalShown(true);
        } catch (e) {
        }
      })();
    }
  }, [data, updatePromoCode, id]);

  // In case, error occurred and error is NotFound, remove promo code
  useEffect(() => {
    if (
      error &&
      isAPIError(error) &&
      apiErrorIncludes(error, APIErrorsEnum.NotFound)
    ) {
      removePromoCode(id);
    }
  }, [error, removePromoCode, id]);

  // In case, promo code has expiration date, we should remove it when this
  // date comes
  useEffect(() => {
    if (expiresAtDate) {
      // Calculate remaining count of milliseconds
      const timeDiff = expiresAtDate.diff(new Date(), 'millisecond');

      // If expiration date came earlier, remove promo
      if (timeDiff <= 0) {
        removePromoCode(id);
        return;
      }

      // Create timeout after which, this promo should be removed
      const timeoutId = setTimeout(() => removePromoCode(id), timeDiff);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [expiresAtDate, removePromoCode, id]);

  return (
    <div className={mc.itemWrapper}>
      <div className={mc.item}>
        <div className={mc.itemInner}>
          <Typography className={mc.title} fontFamily={'helvetica'}>
            {title}
          </Typography>
          {expiresAtDate &&
          <Typography className={mc.expiresAt}>
            {isOpened
              ? 'Истекает ' + expiresAtDate.format('DD MMMM в HH:mm')
              : 'Активировать до ' + expiresAtDate.format('DD MMMM, HH:mm')}
          </Typography>}
          <Button
            className={mc.button}
            variant={'outline'}
            size={'m'}
            fullWidth={true}
            onClick={isOpened ? onView : onActivate}
            disabled={loading}
          >
            {isOpened ? 'Посмотреть' : 'Активировать'}
          </Button>
        </div>
      </div>
      {isModalShown &&
      <Modal onClose={() => setIsModalShown(false)}>
        <Typography
          className={mc.promoValue}
          fontFamily={'helvetica'}
          color={'black'}
        >
          {value}
        </Typography>
        <Typography className={mc.promoHint} color={'black'}>
          Чтобы активировать этот промокод, покажите его администратору
        </Typography>
      </Modal>}
    </div>
  );
});
