import React, {memo} from 'react';

import {makeStyles} from '@material-ui/styles';

import {Button, Theme, useDevice} from 'vkma-ui';
import {Typography} from '../Typography';

import {ReactComponent as DeadIcon} from '../../assets/dead.svg';

interface Props {
  onRestartClick(): void;
  error: string;
}

interface UseStylesProps extends Props {
  bottomInset: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    width: 90,
    margin: '0 auto 16px',
  },
  bottom: {
    flex: '0 0 auto',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: props => `0 16px ${props.bottomInset + 15}px`,
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  error: {whiteSpace: 'pre-wrap', marginBottom: 15},
  header: {fontSize: 21, lineHeight: '26px'},
  dismissButton: {padding: 14},
  dismissIcon: {color: '#818c99'},
}));

/**
 * View which appears when not catched exception raised in application
 * @type {React.NamedExoticComponent<Props>}
 */
export const AppCrashView = memo((props: Props) => {
  const {onRestartClick} = props;
  const {insets} = useDevice();
  const mc = useStyles({...props, bottomInset: insets.bottom});

  return (
    <div className={mc.root}>
      <DeadIcon className={mc.icon}/>
      <Typography className={mc.title}>Упс, что-то сломалось</Typography>
      <div className={mc.bottom}>
        <Button
          className={mc.button}
          color={'primary'}
          size={'xl'}
          fullWidth={true}
          onClick={onRestartClick}
        >
          Перезапустить приложение
        </Button>
      </div>
    </div>
  );
});
