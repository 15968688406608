import React, {memo} from 'react';

import {makeStyles} from '@material-ui/styles';

import {Loader} from '../Loader';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'black',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}, {name: 'AppLoadingView'});

/**
 * View which should be displayed while application is loading
 * @type {React.NamedExoticComponent<object>}
 */
export const AppLoadingView = memo(function AppLoadingView() {
  const mc = useStyles();

  return (
    <div className={mc.root}>
      <Loader/>
    </div>
  );
});
