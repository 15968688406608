import { IdType } from 'vkma-router';

/**
 * Map where key has id type and value is passed type
 */
type Tree<T> = {
  [Id in IdType]: T;
};


/**
 * Describes views tree
 */
export type ViewsTree = Tree<any>;

/**
 * List of available views in project. Required to avoid routing to
 * non-existing views
 */
export enum ViewsEnum {
  Onboarding = 'onboarding',
  Main = 'main',
}

/**
 * List of available panels in project. Required to avoid routing to
 * non-existing panel
 */
export enum PanelsEnum {
  Main = 'main',
  Team = 'team',
  Club = 'club',
  ClubsMap = 'clubs-map',
  Achievements = 'achievements',
  RegisterCheck = 'register-check',
  Profile = 'profile',
  Ranks = 'ranks',
}

/**
 * List of available popups in project. Required to avoid routing to
 * non-existing popup
 */
export enum PopupsEnum {
}

