import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../../../../theme';

export const legacyUseStyles = makeStyles((theme: Theme) => ({
  body: {
    backgroundColor: theme.palette.primary,
    color: 'black',
  },
  top: {
    display: 'flex',
    alignItems: 'stretch',
    borderBottom: '1px solid black',
  },
  topLeft: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid black',
    padding: '34px 20px',
  },
  title: {
    fontSize: 16,
    marginBottom: 18,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    wordBreak: 'break-all',
  },
  topRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 120px',
    fontSize: 60,
  },
  bottom: {
    padding: '38px 20px',
  },
  description: {
    fontSize: 14,
    lineHeight: '12px',
  },
  socials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 35,
  },
  social: {
    display: 'block',
    height: 38,
    width: 38,

    '& + &': {
      marginLeft: 19,
    },
  },
  sliderContainer: {
    position: 'relative',
  },
  sliderDots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
  },
  sliderDot: {
    width: 9,
    height: 9,
    backgroundColor: '#7C7F7E',
    borderRadius: 2,
    transition: '300ms all linear',

    '& + &': {
      marginLeft: 8,
    },
  },
  sliderDotActive: {
    backgroundColor: theme.palette.primary,
  },
  image: {
    display: 'block',
    height: 400,
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
}), {
  name: 'ClubPanel'
});
