import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import {makeStyles} from '@material-ui/styles';
import {Theme} from '../../theme';

import {Typography} from '../Typography';
import {RouterLink} from '../RouterLink';
import {Avatar} from '../Avatar';

import {useSelector} from '../../hooks';

import backgroundImage from './background.png';

interface Props extends HTMLProps<HTMLDivElement> {
  sectionNumber: string;
  sectionTitle: string;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    minHeight: 154,
    position: 'relative',
    padding: '11px 20px 20px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: `url(${backgroundImage}) no-repeat center`,
    backgroundSize: 'cover',
  },
  number: {
    position: 'absolute',
    zIndex: 0,
    left: 0,
    top: 26,
    transform: 'translateX(-35%)',
    fontSize: 145,
    opacity: 0.4,
  },
  title: {
    position: 'relative',
    zIndex: 1,
    color: 'white',
    fontSize: 29,
    lineHeight: '29px',
    textTransform: 'uppercase',
    maxWidth: 245,
    fontWeight: 'bold',
  },
  avatar: {
    marginBottom: 24,
  },
}), {name: 'PanelHead'});

export const PanelHead = memo(function PanelHead(props: Props) {
  const {
    className, title, children, sectionNumber, sectionTitle, ...rest
  } = props;
  const mc = useStyles(props);
  const {profileImageUrl} = useSelector(state => state.user);

  return (
    <div className={c(mc.root, className)} {...rest}>
      <RouterLink pop={true}>
        <Avatar className={mc.avatar} imageUrl={profileImageUrl} size={40}/>
      </RouterLink>
      <Typography className={mc.number} fontFamily={'marsh'} color={'primary'}>
        {sectionNumber}
      </Typography>
      <Typography className={mc.title} fontFamily={'helvetica'}>
        {sectionTitle}
      </Typography>
      {children}
    </div>
  );
});
