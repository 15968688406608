import {Theme} from './types';

const PRIMARY_COLOR = '#F9EC31';
const SECONDARY_COLOR = '#7C7F7E';

export const theme: Theme = {
  components: {
    Button: {
      primary: {
        colors: {
          background: PRIMARY_COLOR,
          border: PRIMARY_COLOR,
          foreground: 'black',
          ripple: 'rgba(0,0,0,.1)',
        },
      },
      outline: {
        colors: {
          background: 'transparent',
          border: PRIMARY_COLOR,
          foreground: PRIMARY_COLOR,
          ripple: `${PRIMARY_COLOR}55`,
        },
      },
      secondary: {
        colors: {
          background: 'transparent',
          border: 'transparent',
          foreground: SECONDARY_COLOR,
          ripple: 'rgba(114,117,116,0.73)',
        },
      },
      tertiary: {
        colors: {
          background: 'transparent',
          border: 'transparent',
          foreground: PRIMARY_COLOR,
          ripple: `${PRIMARY_COLOR}55`,
        },
      },
    },
    PanelHeader: {colors: {background: 'black'}},
    PanelTransition: {colors: {suspender: 'rgba(0,0,0,.15)'}},
    ViewTransition: {colors: {suspender: 'rgba(0,0,0,.15)'}},
    Select: {
      colors: {
        placeholder: `${PRIMARY_COLOR}55`,
        icon: PRIMARY_COLOR,
        foreground: PRIMARY_COLOR,
      },
    },
    Separator: {colors: {background: 'black'}},
    FormControl: {
      colors: {
        border: PRIMARY_COLOR,
        background: 'transparent',
        borderFocused: PRIMARY_COLOR,
      },
    },
    Input: {
      colors: {
        placeholder: `${PRIMARY_COLOR}55`,
        foreground: PRIMARY_COLOR,
      },
    },
    Link: {color: PRIMARY_COLOR},
    Panel: {colors: {background: 'black'}},
  },
  global: {
    backgroundColor: 'black',
    text: {
      fontFamily: '"Iris"',
      fontFamilyTT: '"TT Commons"',
      colors: {
        primary: PRIMARY_COLOR,
        secondary: 'white',
      },
    },
  },
  palette: {
    primary: PRIMARY_COLOR,
    secondary: SECONDARY_COLOR,
  },
};
