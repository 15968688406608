import { useApolloClient } from "@apollo/react-hooks";
import {
  FetchShortLinkDocument,
  FetchShortLinkQuery,
  FetchShortLinkQueryVariables,
} from "../types";
import { useCallback } from "react";

type Fetch = (link: string) => Promise<string>;

/**
 * Returns handler which could fetch short link
 * @returns {Fetch}
 */
export function useGetShortLink(): Fetch {
  const client = useApolloClient();

  return useCallback<Fetch>(
    async (link) => {
      const { data } = await client.query<
        FetchShortLinkQuery,
        FetchShortLinkQueryVariables
      >({ query: FetchShortLinkDocument, variables: { link } });

      return data.getShortLink;
    },
    [client]
  );
}
