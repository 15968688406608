import React, { ChangeEvent, memo, useState } from 'react';
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';

import {useRouter, useStorage,} from '../../../../../hooks';
import {PanelsEnum, StorageFieldEnum, ViewsEnum} from '../../../../../types';
import { TClub } from '../../../../../types/club';

import { legacyUseStyles } from './helpers/legacyUseStyles';
import iconUrl from '../../../../../assets/logo.svg';

import { PanelBody } from '../../../../PanelBody';
import { Typography } from '../../../../Typography';
import { Select } from '../../../../Select';

const DEFAULT_ZOOM = 16;
const MAP_HEIGHT = 350;
const MAP_POINT_SIZE = 20;

interface ClubsPanelBodyProps {
  clubs: TClub[];
}

export const ClubsPanelBody = memo(({ clubs }: ClubsPanelBodyProps) => {
  const router = useRouter();
  const { storage, memoize } = useStorage();

  const mc = legacyUseStyles({});

  const getInitialClub = () => {
    const savedCityId = storage[StorageFieldEnum.MapCityId];
    if (savedCityId) {
      return clubs.find((city) => city.id === parseInt(savedCityId, 10)) || clubs[0];
    }

    return clubs[0];
  }

  const [currentClub, setCurrentClub] = useState(getInitialClub());

  const openClubPanel = (clubId: number) => {
    router.pushState({
      view: ViewsEnum.Main,
      panel: PanelsEnum.Club,
      query: {
        clubId: clubId.toString(),
      },
    });
  };

  const onClubChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const clubId = parseInt(e.currentTarget.value, 10);
    const clubObject = clubs.find((club) => club.id === clubId);

    if (!clubObject) {
      return;
    }

    void memoize(StorageFieldEnum.MapCityId, clubId.toString());
    setCurrentClub({ ...clubObject });
  };

  return (
    <>
      <PanelBody className={mc.panel}>
        <Typography
          className={mc.title}
          fontFamily="helvetica"
          color="primary"
        >
          НАЙДИ СВОЙ COLIZEUM
        </Typography>
        <Typography className={mc.subtitle} color="primary">
          Colizeum – это просто лучшее место для игры. Place to play 24/7
        </Typography>
        <Select
          className={mc.select}
          value={currentClub.id}
          onChange={onClubChange}
          placeholder="Выберите клуб"
        >
          {clubs.map((club) => (
            <option value={club.id} key={club.id}>{club.title}</option>
          ))}
        </Select>
      </PanelBody>
      <YMaps>
        <Map
          height={MAP_HEIGHT}
          width="100%"
          state={{
            center: [parseFloat(currentClub.map.lat), parseFloat(currentClub.map.lng)],
            zoom: DEFAULT_ZOOM,
          }}
        >
          {clubs.map(({ id, map }) => (
            <Placemark
              key={id}
              onClick={() => openClubPanel(id)}
              geometry={[map.lat, map.lng]}
              defaultOptions={{
                iconLayout: 'default#image',
                iconImageHref: iconUrl,
                iconImageSize: [MAP_POINT_SIZE, MAP_POINT_SIZE],
              }}
            />
          ))}
        </Map>
      </YMaps>
      <div className={mc.section}>
        <Typography
          className={mc.sectionCount}
          fontFamily="marsh"
          color="primary"
        >
          {clubs.length}
        </Typography>
        <Typography className={mc.sectionTitle} color="primary">
          клубов<br/>открыто
        </Typography>
      </div>
    </>
  )
});
