import React, { memo, useCallback, useMemo, useState } from 'react';

import {ProfileHead, ProfileLine} from '../Profile';
import {LineSelect} from './LineSelect';
import {LineTextarea} from './LineTextarea';
import {Button} from '../Button';
import {ConfirmationModal} from '../ConfirmationModal';
import {Typography} from '../Typography';

import {makeStyles} from '@material-ui/styles';
import {incline} from '../../utils';
import {useSelectMeta, useSelector} from '../../hooks';

import {Theme} from '../../theme';
import {IdTitlePair} from '../panels/TeamPanel/types';

interface Club extends IdTitlePair {
  city: { id: string };
  address?: string | null;
}

interface Props {
  imageUrl: string;
  name: string;
  rankName?: string;
  age?: number;
  clubId?: string;
  gameId?: string;
  stageId?: string;
  playTimeId?: string;
  about?: string | null;
  cities: IdTitlePair[];
  games: IdTitlePair[];
  stages: IdTitlePair[];
  playTimes: IdTitlePair[];
  clubs: Club[];
  isProfileCreated: boolean;
  isSaving: boolean;
  onSave(
    age: number,
    clubId: string,
    gameId: string,
    stageId: string,
    playTimeId: string,
    about: string | null,
  ): void;
  onCancel(): void;
  onDrop(): void;
}

const useStyles = makeStyles<Theme, Props>({
  utils: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    alignItems: 'stretch',
    padding: 0,
  },
  button: {
    borderRadius: 0,
    border: 'none',
    flex: '0 0 50%',
  },
  confirmText: {
    fontSize: 15,
    lineHeight: '17px',
    textAlign: 'center',
  },
});

export const EditProfile = memo(function EditProfile(props: Props) {
  const {
    onSave, imageUrl, name, rankName, games, playTimes, stages,
    onCancel, onDrop, about: _about = '', isSaving,
    age: _age, clubId: _clubId, gameId: _gameId,
    playTimeId: _playTimeId, stageId: _stageId, isProfileCreated,
  } = props;
  const mc = useStyles(props);
  const [isConfirmShown, setIsConfirmShown] = useState(false);

  // Hides drop confirm modal and calls callback
  const onDropConfirm = useCallback(() => {
    setIsConfirmShown(false);
    onDrop();
  }, [onDrop]);

  const [about, setAbout] = useState(_about || '');
  const [age, ages, onAgeChange] = useSelectMeta(() => {
    return new Array(100)
      .fill(null)
      .map((_, idx) => {
        const age = idx + 5;

        return {
          id: age.toString(),
          title: age + ' ' + incline(age, 'год', 'года', 'лет'),
        };
      });
  }, _age?.toString() || undefined);

  const clubs = useSelector((state) => state.clubs.clubs);
  const [clubId, onClubChange] = useState(_clubId);
  const [game, , onGameChange] = useSelectMeta(games, _gameId);
  const [playTime, , onPlayTimeChange] = useSelectMeta(playTimes, _playTimeId);
  const [stage, , onStageChange] = useSelectMeta(stages, _stageId);

  // Calculate if save is disabled
  const isSaveDisabled = useMemo(() => {
    return !age || !clubId || !game || !stage || !playTime || isSaving;
  }, [age, clubId, game, stage, playTime, isSaving]);

  // Create save button click event handler
  const onSaveClick = useCallback(() => {
    if (age && clubId && game && stage && playTime) {
      onSave(Number(age), clubId, game, stage, playTime, about);
    }
  }, [onSave, age, clubId, game, stage, playTime, about]);

  return (
    <div>
      <ProfileHead title={name} subtitle={rankName} imageUrl={imageUrl}/>
      <LineSelect
        title={'Возраст'}
        placeholder={'Выберите возраст'}
        value={age || undefined}
        values={ages}
        onChange={onAgeChange}
      />
      <LineSelect
        title="Клуб"
        placeholder="Выберите клуб"
        value={clubId || undefined}
        values={clubs ? clubs.map((club) => ({ id: club.id.toString(), title: club.title })) : []}
        onChange={(e) => onClubChange(e.currentTarget.value)}
      />
      <LineSelect
        title={'Игра'}
        placeholder={'Выберите игру'}
        value={game || undefined}
        values={games}
        onChange={onGameChange}
      />
      <LineSelect
        title={'Игровой стаж'}
        placeholder={'Выберите игровой стаж'}
        value={stage || undefined}
        values={stages}
        onChange={onStageChange}
      />
      <LineSelect
        title={'Время игры'}
        placeholder={'Выберите время игры'}
        value={playTime || undefined}
        values={playTimes}
        onChange={onPlayTimeChange}
      />
      <LineTextarea
        title={'О себе'}
        placeholder={'Расскажите о себе'}
        value={about}
        onChange={setAbout}
      />
      {isProfileCreated &&
      <ProfileLine className={mc.utils}>
        <Button
          variant={'outline'}
          size={'l'}
          onClick={() => setIsConfirmShown(true)}
          disabled={isSaving}
        >
          Стереть анкету
        </Button>
      </ProfileLine>}
      <ProfileLine className={mc.buttons}>
        <Button
          className={mc.button}
          variant={'primary'}
          size={'xl'}
          onClick={onSaveClick}
          disabled={isSaveDisabled}
        >
          Сохранить
        </Button>
        <Button
          className={mc.button}
          variant={'tertiary'}
          size={'xl'}
          onClick={onCancel}
        >
          Отменить
        </Button>
      </ProfileLine>
      {isConfirmShown &&
      <ConfirmationModal
        onDecline={() => setIsConfirmShown(false)}
        onConfirm={onDropConfirm}
      >
        <Typography className={mc.confirmText}>
          Вы действительно хотите стереть анкету?
        </Typography>
      </ConfirmationModal>}
    </div>
  );
});
