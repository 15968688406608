/**
 * List of keys, which can be placed into bridge storage
 */
export enum StorageFieldEnum {
  OnboardingCompleted = 'onboarding-completed',
  MapCityId = 'mapClubIdV2',
}

/**
 * Describes which bridge storage key has stated data type
 */
export interface StorageValuesMap {
  [StorageFieldEnum.OnboardingCompleted]: boolean;
  [StorageFieldEnum.MapCityId]: string | null;
}
