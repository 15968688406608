import { makeStyles } from '@material-ui/styles';

export const legacyUseStyles = makeStyles(() => ({
  panel: {
    paddingBottom: 0,
  },
  title: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 'bold',
    marginBottom: 18,
  },
  subtitle: {
    fontSize: 17,
    lineHeight: '20px',
    marginBottom: 29,
    maxWidth: 274,
  },
  select: {
    marginBottom: 30,
  },
  section: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginTop: '35px',
  },
  sectionCount: {
    fontSize: 70,
    lineHeight: '45px',
    textAlign: 'center',
    width: 100,
  },
  sectionTitle: {
    fontSize: 21,
    lineHeight: '19px',
    padding: '0 0 1px 18px',
  },
}), {
  name: 'ClubsPanel'
});
