import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DefaultPanelHeader } from '../../DefaultPanelHeader';
import { PanelHead } from '../../PanelHead';
import { useRouter } from '../../../hooks';
import { LoadingBody } from '../../LoadingBody/LoadingBody';

import { ClubPanelNotFound } from './components/ClubPanelNotFound/ClubPanelNotFound';
import { ClubPanelInfo } from './components/ClubPanelInfo/ClubPanelInfo';
import { TFullClub } from '../../../types/club';
import { requestClubById } from '../../../requests/request';

export const ClubPanel = memo(function ClubPanel() {
  const router = useRouter();

  const clubId = useMemo(() => {
    const { clubId } = router.currentState.query;

    if (typeof clubId !== 'string') {
      return null;
    }

    return parseInt(clubId, 10);
  }, [router]);

  const [club, setClub] = useState<null|undefined|TFullClub>(undefined);

  useEffect(() => {
    if (club || typeof clubId !== 'number') {
      return;
    }

    void requestClubById(clubId).then((data) => {
      setClub(data);
    });
  }, [club, clubId]);

  const Component = () => {
    if (club) {
      return <ClubPanelInfo club={club} />;
    }

    if (clubId === null) {
      return <ClubPanelNotFound />;
    }

    return <LoadingBody />;
  };

  const getSectionTitle = () => {
    if (!club) {
      return 'Клуб';
    }

    return club.title;
  };

  return (
    <div>
      <DefaultPanelHeader/>
      <PanelHead
        sectionNumber="05"
        sectionTitle={getSectionTitle()}
      />
      <Component />
    </div>
  );
});
