import React, {memo, ReactNode, useEffect, useMemo} from 'react';

import {Button} from '../Button';
import {DefaultPanelHeader} from '../DefaultPanelHeader';
import {PanelHead} from '../PanelHead';
import {PanelBody} from '../PanelBody';
import {RouterLink} from '../RouterLink';
import {Loader} from '../Loader';
import {UserProfile} from '../UserProfile';
import {ErrorMessage} from '../ErrorMessage';

import {
  FetchUserProfileDocument,
  FetchUserProfileQuery,
  FetchUserProfileQueryVariables,
} from '../../types';

import {makeStyles} from '@material-ui/styles';
import {useRouter} from '../../hooks';
import {useLazyQuery} from '@apollo/react-hooks';

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const ProfilePanel = memo(function ProfilePanel() {
  const {currentState} = useRouter();
  const mc = useStyles({});

  const vkUserId = useMemo(() => {
    const {query} = currentState;
    const asNum = Number(query.vkUserId);
    return Number.isNaN(asNum) ? null : asNum;
  }, [currentState]);

  const [fetchUserProfile, {data, loading, error, refetch}] =
    useLazyQuery<FetchUserProfileQuery, FetchUserProfileQueryVariables>(
      FetchUserProfileDocument, {fetchPolicy: 'network-only'},
    );

  // Fetch when component is mounted
  useEffect(() => {
    if (typeof vkUserId === 'number') {
      fetchUserProfile({variables: {vkUserId}});
    }
  }, [vkUserId, fetchUserProfile]);

  let content: ReactNode = null;

  if (error) {
    content = (
      <ErrorMessage
        title={'Произошла ошибка'}
        subtitle={'Нам не удалось получить информацию об этом пользователе'}
      >
        <Button
          variant={'primary'}
          size={'m'}
          onClick={() => refetch()}
        >
          Попробовать еще раз
        </Button>
      </ErrorMessage>
    );
  } else if (data) {
    if (data.profile) {
      const {playTime, game, club, age, about, stage, user} = data.profile;
      const {vkUserId, profileImageUrl, name, rank} = user;

      content = (
        <UserProfile
          imageUrl={profileImageUrl}
          game={game.title}
          city={club.city.title}
          club={club.title}
          name={name}
          rankName={rank?.title}
          age={age}
          stage={stage.title}
          playTime={playTime.title}
          vkUserId={vkUserId}
          about={about}
        />
      );
    } else {
      content = (
        <ErrorMessage
          title={'Анкета не найдена'}
          subtitle={'Похоже, что у этого пользователя еще нет анкеты'}
        >
          <RouterLink pop={true}>
            <Button variant={'primary'} size={'m'}>Вернуться</Button>
          </RouterLink>
        </ErrorMessage>
      );
    }
  } else if (loading) {
    content = (
      <div className={mc.loaderWrapper}>
        <Loader/>
      </div>
    );
  } else if (vkUserId === null) {
    content = (
      <ErrorMessage
        title={'Пользователь не найден'}
        subtitle={
          'Похоже, что идентификатор пользователя не был указан. ' +
          'Пожалуйста, сообщите об этой ошибке администрации COLIZEUM'
        }
      >
        <RouterLink pop={true}>
          <Button variant={'primary'} size={'m'}>Вернуться</Button>
        </RouterLink>
      </ErrorMessage>
    );
  }

  return (
    <div>
      <DefaultPanelHeader/>
      <PanelHead sectionNumber={'06'} sectionTitle={'Анкета пользователя'}/>
      <PanelBody>
        {content}
      </PanelBody>
    </div>
  );
});
