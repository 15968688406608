import {ofType, unionize, UnionOf} from 'unionize';

export interface Rank {
  title: string;
  experience: number;
  imageUrl: string;
}

export interface RanksReducerState {
  ranks?: Rank[];
}

export const ranksActions = unionize({
  setRanks: ofType<Rank[]>(),
}, {
  tag: 'type',
  value: 'payload',
} as const);

export type RanksAction = UnionOf<typeof ranksActions>;

const initState: RanksReducerState = {};

export const ranksReducer = (
  state = initState,
  action: RanksAction,
): RanksReducerState => {
  return ranksActions.match(action, {
    default: () => state,
    setRanks: ranks => ({...state, ranks}),
  });
};
