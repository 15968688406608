import {withStyles} from '@material-ui/styles';
import {Typography} from '../Typography';
import {Theme} from '../../theme';

export const ProfileLineTitle = withStyles((theme: Theme) => ({
  root: {
    fontSize: 15,
    lineHeight: '15px',
    marginBottom: 10,
    color: theme.palette.primary,
    fontFamily: theme.global.text.fontFamily,
  },
}))(Typography);
