import React, {PureComponent} from 'react';

import {AppLoadingView} from '../views/AppLoadingView';
import {AppCrashView} from '../views/AppCrashView';
import {App} from '../App';
import {
  NonIndexedHistory,
  Router,
} from 'vkma-router';
import {ServicePanel} from '../misc/ServicePanel';
import {
  DeviceProvider,
  ThemeProviderConnected,
  GlobalStyleSheet,
  getLaunchParams,
} from 'vkma-ui';
import {VKStorageProvider} from '../VKStorageProvider';
import {ConfigProvider} from '../ConfigProvider';
import {ApolloProvider} from '@apollo/react-hooks';
import {Provider as StoreProvider, ReactReduxContext} from 'react-redux';

import {createReduxStore, ReduxState} from '../../redux';
import {apiErrorIncludes, createApolloClient, isAPIError} from '../../utils';
import {appRootContext} from './context';
import {getStorageKeys} from '../../utils';
import {AppTree, routingTree} from '../../trees';
import config from '../../config';
import {theme} from '../../theme';
import vkBridge from '@vkontakte/vk-bridge';

import {AppRootState, AppRootContext} from './types';
import {
  PanelsEnum,
  StorageFieldEnum,
  StorageValuesMap,
  ViewsEnum,
  RegisterDocument,
  APIErrorsEnum, EmitAppVisitedEventDocument,
} from '../../types';
import {Store} from 'redux';
import {ApolloClient} from 'apollo-client';
import {clubsActions} from '../../redux/clubs';
import {requestAllClubs} from '../../helpers/requestAllClubs';

const {Provider: AppRootProvider} = appRootContext;

// Assign human-readable store provider name for debugging purposes
ReactReduxContext.displayName = 'StoreProvider';

/**
 * Root application component. Everything application requires for showing
 * first screen is being loaded here
 */
export class AppRoot extends PureComponent<{}, AppRootState> {
  /**
   * Application root context
   * @type {{init: () => Promise<void>}}
   */
  private appRootContext: AppRootContext = {init: this.init.bind(this)};

  /**
   * Redux store
   * @type {Store<ReduxState>}
   */
  private readonly store: Store<ReduxState> = createReduxStore();

  /**
   * Apollo client
   */
  private readonly client: ApolloClient<any>;

  /**
   * Application launch parameters
   * @type {LaunchParams}
   */
  private readonly launchParams = getLaunchParams(window.location.search.slice(1));

  public state: AppRootState = {
    loading: true,
  };

  public constructor(props: any) {
    super(props);

    const {gqlHttpUrl, gqlWsUrl} = config;
    this.client = createApolloClient(
      gqlHttpUrl, gqlWsUrl, window.location.search.slice(1),
    );
  }


  public async componentDidMount() {
    // Update device interface colors if required
    if (vkBridge.supports('VKWebAppSetViewSettings')) {
      await vkBridge.send('VKWebAppSetViewSettings', {
        status_bar_style: 'light',
        action_bar_color: 'black',
      });
    }

    // Init application
    await this.init();
  }

  public componentDidCatch(error: Error) {
    // Catch error if it did not happen before
    this.setState({error: error.message});
  }

  public render() {
    const {loading, error, history, storage} = this.state;

    if (loading || !storage || !history || error) {
      const init = this.init.bind(this);

      return (
        <DeviceProvider automaticUpdate={true}>
          <ThemeProviderConnected theme={theme}>
            <GlobalStyleSheet>
              {error
                ? <AppCrashView onRestartClick={init} error={error}/>
                : <AppLoadingView/>}
            </GlobalStyleSheet>
          </ThemeProviderConnected>
        </DeviceProvider>
      );
    }
    const {store, appRootContext, launchParams} = this;

    return (
      <AppRootProvider value={appRootContext}>
        <StoreProvider store={store}>
          <VKStorageProvider storage={storage}>
            <DeviceProvider automaticUpdate={true}>
              <ConfigProvider
                launchParams={launchParams}
                envConfig={config}
                automaticUpdate={true}
              >
                <ApolloProvider client={this.client}>
                  <ThemeProviderConnected theme={theme}>
                    <Router initialHistory={history} tree={routingTree}>
                      <GlobalStyleSheet>
                        <App/>
                        <ServicePanel/>
                      </GlobalStyleSheet>
                    </Router>
                  </ThemeProviderConnected>
                </ApolloProvider>
              </ConfigProvider>
            </DeviceProvider>
          </VKStorageProvider>
        </StoreProvider>
      </AppRootProvider>
    );
  }

  /**
   * Initializes application
   */
  private async init() {
    requestAllClubs().then((clubs) => this.store.dispatch(clubsActions.setClubs(clubs)));

    this.setState({loading: true, error: undefined});

    try {
      // Performing all async operations and getting data to launch application
      try {
        const [storage] = await Promise.all([
          // Get VK storage values
          getStorageKeys<StorageValuesMap>(...Object.values(StorageFieldEnum)),
          // Emit event which states, user visited application
          this.client.mutate({mutation: EmitAppVisitedEventDocument})
        ]);

        // Create history depending on initial data
        const history: NonIndexedHistory<AppTree> = [];
        if (storage[StorageFieldEnum.OnboardingCompleted]) {
          history.push({
            view: ViewsEnum.Main,
            panel: PanelsEnum.Main,
            query: {},
            popup: null,
          });
        } else {
          history.push({
            view: ViewsEnum.Onboarding,
            panel: PanelsEnum.Main,
            query: {},
            popup: null,
          });
        }

        // this.setState({loading: false, storage: {}, history});
        this.setState({loading: false, storage, history});
      } catch (e) {
        // In case, API error occurred and user was not found. Register him
        // and repeat initialization
        if (
          isAPIError(e) &&
          apiErrorIncludes(e, APIErrorsEnum.UserNotFound)
        ) {
          // Register
          await this.client.mutate({mutation: RegisterDocument});

          // Re-init
          this.init();
        } else {
          throw e;
        }
      }
    } catch (e) {
      // In case error appears, catch it and display
      this.setState({error: e.message, loading: false});
    }
  }
}
