import React, {
  memo,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import {ProfileHead} from '../../Profile';
import {UserProfile} from '../../UserProfile';
import {Typography} from '../../Typography';
import {Select} from '../../Select';
import {ArrowDownSvg} from 'vkma-ui';
import {Button} from '../../Button';
import {Loader} from '../../Loader';

import {makeStyles} from '@material-ui/styles';
import {useSelectMeta, useSelector} from '../../../hooks';
import {useLazyQuery} from '@apollo/react-hooks';

import {IdTitlePair} from './types';
import {
  SearchProfilesDocument,
  SearchProfilesQuery,
  SearchProfilesQueryVariables,
} from '../../../types';

interface Props {
  imageUrl: string;
  cities: IdTitlePair[];
  clubId?: string;
  games: IdTitlePair[];
  gameId: string;
  onEdit(): void;
}

const useStyles = makeStyles(() => ({
  profileTitle: {
    flex: '1 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  profileTitleArrow: {
    width: 24,
  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontSize: 29,
    lineHeight: '29px',
    margin: '35px 0 24px',
    fontWeight: 'bold',
  },
  select: {
    '& + &': {
      marginTop: 20,
    },
  },
  button: {
    marginTop: 20,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  notFound: {
    fontSize: 16,
    lineHeight: '20px',
  },
  profile: {
    '& + &': {
      marginTop: 20,
    },
  },
}));

export const SearchProfiles = memo(function SearchProfiles(props: Props) {
  const {
    imageUrl, onEdit, games, clubId: _clubId, gameId: _gameId,
  } = props;
  const mc = useStyles(props);
  const clubs = useSelector((state) => state.clubs.clubs);

  const [isSearching, setIsSearching] = useState(false);
  const firstClubId = clubs ? clubs[0].id : undefined;

  const getInitCityId = () => {
    if (_clubId) {
      return _clubId;
    }

    if (firstClubId) {
      return firstClubId.toString();
    }

    return undefined;
  };

  const [clubId, onClubChange] = useState(getInitCityId());
  const [gameId, , onGameChange] = useSelectMeta(games, _gameId);

  const [searchProfiles, {data}] = useLazyQuery<SearchProfilesQuery,
    SearchProfilesQueryVariables>(SearchProfilesDocument, {
    fetchPolicy: 'network-only',
  });

  // Triggers search on search button click
  const onSearchClick = useCallback(async () => {
    setIsSearching(true);
    try {
      await searchProfiles({variables: { clubId, gameId }});
    } catch (e) {
    }
    setIsSearching(false);
  }, [searchProfiles, clubId, gameId]);

  let content: ReactNode;
  if (isSearching || !clubs) {
    content = (
      <div className={mc.loaderWrapper}>
        <Loader/>
      </div>
    );
  } else if (data && data.profiles && data.profiles.length > 0) {
    content = data.profiles.map((p, idx) => {
      const {playTime, age, user, about, stage} = p;
      const {vkUserId, profileImageUrl, rank, name} = user;

      return (
        <UserProfile
          className={mc.profile}
          vkUserId={vkUserId}
          imageUrl={profileImageUrl}
          playTime={playTime.title}
          rankName={rank?.title || 'Ранг неизвестен'}
          name={name}
          age={age}
          stage={stage.title}
          about={about}
          key={idx}
        />
      );
    });
  } else {
    content = (
      <Typography className={mc.notFound} color={'primary'}>
        Анкеты удовлетворяющие запросу не найдены
      </Typography>
    );
  }

  return (
    <div>
      <ProfileHead
        imageUrl={imageUrl}
        onClick={onEdit}
        title={
          <div className={mc.profileTitle}>
            <span>Моя анкета</span>
            <ArrowDownSvg className={mc.profileTitleArrow}/>
          </div>
        }
      />
      <Typography
        className={mc.sectionTitle}
        fontFamily="helvetica"
        color="primary"
      >
        Поиск пати
      </Typography>
      <Select
        className={mc.select}
        value={clubId}
        onChange={(event) => onClubChange(event.currentTarget.value)}
        placeholder="Выберите клуб"
        disabled={isSearching}
      >
        {clubs?.map(({ id, title}) => (
          <option value={id.toString()} key={id}>{title}</option>
        ))}
      </Select>
      <Select
        className={mc.select}
        value={gameId || undefined}
        onChange={onGameChange}
        placeholder="Выберите игру"
        disabled={isSearching}
      >
        {games.map(({ id, title}) => (
          <option value={id} key={id}>{title}</option>
        ))}
      </Select>
      <Button
        className={mc.button}
        variant="primary"
        size="xl"
        fullWidth
        disabled={!clubId || !gameId || isSearching}
        onClick={onSearchClick}
      >
        Поиск
      </Button>
      <Typography
        className={mc.sectionTitle}
        fontFamily="helvetica"
        color="primary"
      >
        Анкеты
      </Typography>
      {content}
    </div>
  );
});
