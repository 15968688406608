import React, {ChangeEvent, memo, useCallback} from 'react';

import {ProfileLine} from '../Profile/ProfileLine';
import {ProfileLineTitle} from '../Profile/ProfileLineTitle';
import {Textarea} from '../Textarea';

import {styled} from '@material-ui/styles';

interface Props {
  title: string;
  placeholder: string;
  value: string;
  onChange(value: string): void;
}

const StyledTextarea = styled(Textarea)({
  resize: 'none',
  border: 'none',
});

export const LineTextarea = memo(function LineSelect(props: Props) {
  const {title, value, onChange, placeholder} = props;
  const onTextAreaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <ProfileLine>
      <ProfileLineTitle>{title}</ProfileLineTitle>
      <StyledTextarea
        value={value}
        onChange={onTextAreaChange}
        placeholder={placeholder}
        minRows={5}
        maxLength={300}
      />
    </ProfileLine>
  );
});
