import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import {makeStyles} from '@material-ui/styles';

import {Theme} from '../../theme';

interface Props extends HTMLProps<HTMLDivElement> {
  value: number;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    background: 'repeating-linear-gradient(135deg, ' +
      `${theme.palette.primary}88 0, ` +
      `${theme.palette.primary}88 8px, transparent 8px, transparent 14px)`,
    height: 16,
    overflow: 'hidden',
  },
  progress: {
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',

    '&:before': {
      content: '""',
      flex: '1 0 0',
      backgroundColor: theme.palette.primary,
    },

    '&:after': {
      content: '""',
      flex: '0 0 16px',
      border: `8px solid ${theme.palette.primary}`,
      boxSizing: 'border-box',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
  },
}));

export const ProgressBar = memo(function Progress(props: Props) {
  const {value, max, className, ...rest} = props;
  const mc = useStyles(props);

  return (
    <div className={c(mc.root, className)} {...rest}>
      <div className={mc.progress} style={{width: `calc(${value}% + 16px)`}}/>
    </div>
  );
});
