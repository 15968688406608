import React, { memo } from 'react';

import { DefaultPanelHeader } from '../../DefaultPanelHeader';
import { PanelHead } from '../../PanelHead';

import { useSelector } from '../../../hooks';

import { ClubsPanelBody } from './components/ClubsPanelBody/ClubsPanelBody';
import { ClubsPanelError } from './components/ClubsPanelError/ClubsPanelError';
import { LoadingBody } from '../../LoadingBody/LoadingBody';
import { requestAllClubs } from '../../../helpers/requestAllClubs';

const ClubsPanel = memo(() => {
  const clubs = useSelector((state) => state.clubs.clubs);

  const Component = () => {
    if (Array.isArray(clubs) && clubs.length > 0) {
      return <ClubsPanelBody clubs={clubs} />;
    }

    if (clubs === null) {
      return <ClubsPanelError retryFunction={requestAllClubs} />;
    }

    return <LoadingBody />;
  };

  return (
    <div>
      <DefaultPanelHeader/>
      <PanelHead sectionNumber="02" sectionTitle="Карта клубов" />
      <Component />
    </div>
  );
});

export { ClubsPanel };
