import { TClub } from '../types/club';
import { requestClubsByPage } from '../requests/request';

const CLUBS_PAGE_COUNT = 4;

export const requestAllClubs = (): Promise<TClub[]> => {
  const promises = Array.from(Array(CLUBS_PAGE_COUNT).keys())
      .map((pageNumber) => requestClubsByPage(pageNumber + 1));

  return new Promise((resolve, reject) => {
    Promise.all(promises).then((clubsOfClubs) => {
      resolve(clubsOfClubs.reduce((prev, curr) => prev.concat(curr)));
    }).catch(reject);
  });
};
