import React, {memo, useMemo, useState} from "react";
import c from "classnames";

import { makeStyles, withStyles } from "@material-ui/styles";

import { PanelBody } from "../../PanelBody";
import MuiAccordion from "@material-ui/core/Accordion";

import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography } from "../../Typography";

import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";

import { Theme } from "../../../theme";
import { TPrice } from '../../../types/club';

interface Props {
  tariffs: TPrice[];
}

const Accordion = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary}`,

    "& + &": {
      borderTop: "none",
    },
  },
  expanded: {
    "&$root": {
      margin: 0,
    },
  },
}))(MuiAccordion);
const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    padding: "0 16px",
    minHeight: "auto",
  },
  content: {
    textTransform: "uppercase",
    color: theme.palette.primary,
  },
}))(MuiAccordionSummary);
const AccordionDetails = withStyles({
  root: {
    color: "white",
    display: "block",
    padding: "0 16px",
  },
})(MuiAccordionDetails);

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    backgroundColor: "black",
  },
  title: {
    textTransform: "uppercase",
    textAlign: "center",
    color: theme.palette.primary,
    fontSize: 16,
    lineHeight: "16px",
    padding: "14px 0",
    border: `1px solid ${theme.palette.primary}`,
  },
  list: {
    border: `solid ${theme.palette.primary}`,
    borderWidth: "0 1px",
    padding: "20px 0",
  },
  listItem: {
    padding: "10px 16px",
    color: theme.palette.primary,
    fontSize: 14,
    lineHeight: "12px",
  },
  listItemActive: {
    backgroundColor: theme.palette.primary,
    color: "black",
  },
  disclaimer: {
    color: theme.palette.primary,
    marginTop: 16,
    fontSize: 14,
    lineHeight: "12px",
  },
  packet: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #7C7F7E",
    padding: "24px 16px",
  },
}));

type TPriceOld = {
  period: string;
  price: string;
};

const Tariffs = memo((props: Props) => {
  const mc = useStyles(props);
  const { tariffs } = props;

  const [tariff, setTariff] = useState(0);
  const [day, setDay] = useState<number | null>(null);

  const { weekdays, weekend } = useMemo(() => {
    const weekdays: TPriceOld[] = [];
    const weekend: TPriceOld[] = [];

    tariffs[tariff].table.body.forEach(([period, weekdaysPrice, weekendPrice]) => {
      weekdays.push({
        period: period.c,
        price: weekdaysPrice.c,
      });
      weekend.push({
        period: period.c,
        price: weekendPrice.c,
      });
    });

    return { weekdays, weekend }
  }, [tariffs, tariff]);

  return (
    <PanelBody className={mc.root}>
      <Typography fontFamily={"helvetica"} className={mc.title}>
        Тарифы
      </Typography>
      <div className={mc.list}>
        {tariffs.map(({ title }, index) => {
          const className = c(mc.listItem, {
            [mc.listItemActive]: tariff === index,
          });

          return (
            <Typography
              key={index}
              className={className}
              onClick={() => setTariff(index)}
            >
              {title}
            </Typography>
          );
        })}
      </div>

      {['Будни', 'Выходные'].map((name: string, index) => (
        <Accordion
          square
          key={index}
          expanded={index === day}
          onChange={(_: any, expanded: boolean) => setDay(expanded ? index : null)}
        >
          <AccordionSummary expandIcon={<ArrowDown color={"primary"} />}>
            {name}
          </AccordionSummary>
          <AccordionDetails>
            {(index === 0 ? weekdays : weekend).map(({ period, price }: TPriceOld, index) => (
              <div className={mc.packet} key={index}>
                <Typography><div dangerouslySetInnerHTML={{ __html: period }} /></Typography>
                <Typography>{price}</Typography>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Typography className={mc.disclaimer}>
        *При оплате пакета на PC
      </Typography>
    </PanelBody>
  );
});

export default Tariffs;
