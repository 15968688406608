import {useCallback, useEffect, useState} from 'react';
import {useActions} from './useActions';
import {teamActions} from '../redux/team';
import {useMutation} from '@apollo/react-hooks';
import {
  JoinTeamDocument,
  JoinTeamMutation,
  JoinTeamMutationVariables, LeaveTeamDocument,
} from '../types';

type Tuple = [
  (vkUserId: number) => Promise<void>,
  () => Promise<void>,
  boolean,
];

export function useTeamHandlers(): Tuple {
  const [isProcessing, setIsProcessing] = useState(false);
  const setTeammates = useActions(teamActions.setTeammates);
  const [joinTeam, joinTeamResult] =
    useMutation<JoinTeamMutation, JoinTeamMutationVariables>(JoinTeamDocument);
  const [leaveTeam] = useMutation(LeaveTeamDocument);

  // Joins team
  const join = useCallback(async (vkUserId: number) => {
    setIsProcessing(true);
    try {
      await joinTeam({variables: {vkUserId}});
    }
    catch (e) {
    }
    setIsProcessing(false);
  }, [joinTeam]);

  // Leaves team
  const leave = useCallback(async () => {
    setIsProcessing(true);
    try {
      await leaveTeam();
      setTeammates([]);
    }
    catch (e) {
    }
    setIsProcessing(false);
  }, [leaveTeam, setTeammates]);

  // In case, user joined some team, update redux team
  useEffect(() => {
    if (joinTeamResult.data) {
      setTeammates(joinTeamResult.data.joinTeam.users.map(u => ({
        name: u.name,
        profileImageUrl: u.profileImageUrl,
        rankName: u.rank?.title,
        vkUserId: u.vkUserId,
      })))
    }
  }, [joinTeamResult, setTeammates]);

  return [join, leave, isProcessing];
}
