import {APIError, APIErrorsEnum} from '../types';
import ApolloClient, {isApolloError} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {WebSocketLink} from 'apollo-link-ws';
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';

/**
 * Detects if error is sent from Apollo API
 * @param e
 * @returns {e is GQLError}
 */
export function isAPIError(e: any): e is APIError {
  return isApolloError(e);
}

/**
 * Returns true in case APIError includes passed error
 * @param {APIError} e
 * @param {APIErrorsEnum} error
 * @returns {boolean}
 */
export function apiErrorIncludes(
  e: APIError,
  error: APIErrorsEnum,
): boolean {
  return e.graphQLErrors.some(err => err.name === error)
}

/**
 * Creates ApolloClient with websocket- and http-link
 * @returns {ApolloClient<any>}
 * @param httpURI
 * @param wsURI
 * @param launchParams
 */
export function createApolloClient(
  httpURI: string,
  wsURI: string,
  launchParams: string,
): ApolloClient<any> {
  // We can authenticate users only with launch parameters sent from VKontakte.
  // To check them on server side, we send them in header
  const httpLink = new HttpLink({
    uri: httpURI,
    headers: {'x-launch-params': launchParams},
  });

  // In WebSocket connection we are using connectionParams instead of headers,
  // because there is no another way we can authenticate user.
  // You are free to remove this variable if there are no subscriptions in
  // your project and use "httpLink" instead of "link"
  const wsLink = new WebSocketLink({
    uri: wsURI,
    options: {
      reconnect: true,
      connectionParams: {'x-launch-params': launchParams},
    },
  });

  const link = split(
    ({query}) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
