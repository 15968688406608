import {EMethods} from './EMethods';

const locationParams = new URLSearchParams(window.location.search);
const scheme = locationParams.has('odr_enabled') ? 'vkcors' : 'https';

const API_URL = `${scheme}://colizeumarena.com/wp-json/wp/v2/`;

// Дефолтный апи ключ до моменты, когда прикрутят настоящую авторизацию
const API_ACCESS_TOKEN = 'Y29saXpldW1fYXBpX3ZrOkhuVyMqUWJhQG1vSElEWV4='; // btoa(login + password);

const CLUBS_COUNT_FOR_LOAD = 100;

const request = (method: string, queryParams: string) => {
  return fetch(`${API_URL}${method}?${queryParams}`, {
    headers: {
      Authorization: `Basic ${API_ACCESS_TOKEN}`,
    },
  }).then((data) => data.json());
}

export const requestClubsByPage = (pageNumber: number) => {
  return request(EMethods.CLUBS_LIST, new URLSearchParams({
    per_page: CLUBS_COUNT_FOR_LOAD.toString(),
    page: pageNumber.toString(),
  }).toString());
};

export const requestClubById = (clubId: number) => {
  return request(EMethods.CLUB_BY_ID.replace('{id}', clubId.toString()), '');
}