import React, {memo} from 'react';

import {ActionSheet, ActionSheetItem} from '@vkontakte/vkui';

import {makeStyles} from '@material-ui/styles';

import {ReactComponent as PostSvg} from '../../assets/icons/post.svg';
import {ReactComponent as StorySvg} from '../../assets/icons/story.svg';

import {OS, useDevice} from 'vkma-ui';

interface Props {
  onWallClick(): void;
  onStoryClick(): void;
  onClose(): void;
}

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    zIndex: 1000,
  },
});

export const SharingSelection = memo(function SharingSelection(props: Props) {
  const {onStoryClick, onWallClick, onClose} = props;
  const mc = useStyles(props);
  const {os} = useDevice();

  return (
    <ActionSheet className={mc.root} onClose={onClose}>
      <ActionSheetItem
        autoclose={true}
        onClick={onWallClick}
        before={<PostSvg/>}
      >
        Опубликовать на стене
      </ActionSheetItem>
      <ActionSheetItem
        autoclose={true}
        onClick={onStoryClick}
        before={<StorySvg/>}
      >
        Опубликовать в истории
      </ActionSheetItem>
      {os === OS.IOS &&
      <ActionSheetItem autoclose mode={'cancel'} onClick={onClose}>
        Отмена
      </ActionSheetItem>}
    </ActionSheet>
  );
});
