import React, {memo} from 'react';
import c from 'classnames';

import {Typography} from '../../Typography';
import {Button} from '../../Button';
import {ProfileHead} from '../../Profile';

import {makeStyles} from '@material-ui/styles';

import {Theme} from '../../../theme';

interface Props {
  imageUrl: string;
  name: string;
  rankName?: string;
  onFillClick(): void;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {},
  line: {
    border: `1px solid ${theme.palette.primary}`,

    '& + &': {
      borderTopWidth: 0,
    },
  },
  profileHead: {
    borderBottom: 'none',
  },
  textLine: {
    padding: '25px 16px',
    fontSize: 17,
    lineHeight: '15px',
  },
  headLine: {
    display: 'flex',
    alignItems: 'stretch',
    height: 70,
  },
  headLineLeft: {
    flex: '0 0 70px',
    height: '100%',
    padding: 14,
    boxSizing: 'border-box',
    borderRight: `1px solid ${theme.palette.primary}`,
  },
  headLineRight: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '16px',
    paddingLeft: 24,
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: '100%',
    width: '100%',
    background: 'no-repeat center',
    backgroundSize: 'contain',
  },
  noRankImage: {
    width: '100%',
  },
  button: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export const EmptyProfile = memo(function (props: Props) {
  const mc = useStyles(props);
  const {onFillClick, imageUrl, name, rankName} = props;

  return (
    <div className={mc.root}>
      <ProfileHead
        className={mc.profileHead}
        title={name}
        subtitle={rankName}
        imageUrl={imageUrl}
      />
      <Typography
        className={c(mc.line, mc.textLine)}
        fontFamily={'iris'}
        color={'primary'}
      >
        Чтобы найти напарников, сперва необходимо заполнить анкету
      </Typography>
      <Button
        className={mc.button}
        variant={'primary'}
        size={'xl'}
        fullWidth={true}
        onClick={() => onFillClick()}
      >
        Заполнить
      </Button>
    </div>
  );
});
