import React from 'react';

import { Button } from '../../../../Button';
import { ErrorMessage } from '../../../../ErrorMessage';
import { PanelBody } from '../../../../PanelBody';

interface ClubsPanelLoadingProps {
  retryFunction: () => void;
}

export const ClubsPanelError = ({ retryFunction }: ClubsPanelLoadingProps) => (
  <PanelBody>
    <ErrorMessage
      title="Произошла ошибка"
      subtitle="Нам не удалось получить информацию о клубах"
    >
      <Button
        onClick={retryFunction}
        variant="primary"
        size="m"
      >
        Попробовать еще раз
      </Button>
    </ErrorMessage>
  </PanelBody>
);
