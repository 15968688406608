import React from 'react';

import { legacyUseStyles } from './helpers/legacyUseStyles';

import { Loader } from '../Loader';
import { PanelBody } from '../PanelBody';

export const LoadingBody = () => {
  const mc = legacyUseStyles({});

  return (
    <PanelBody>
      <div className={mc.loadingWrapper}>
        <Loader />
      </div>
    </PanelBody>
  );
}
