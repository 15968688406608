import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import {makeStyles} from '@material-ui/styles';

import borderImage from '../../assets/circle-border.png';
import borderBlackImage from '../../assets/circle-border-black.png';

import {Theme} from '../../theme';

interface Props extends HTMLProps<HTMLDivElement> {
  size?: number;
  imageUrl: string;
  invert?: boolean;
}

const DEFAULT_SIZE = 40;

const useStyles = makeStyles<Theme, Props>({
  root: {
    position: 'relative',
    background: ({invert}) => `url(${
      invert ? borderBlackImage : borderImage
    }) no-repeat center`,
    backgroundSize: () => 'contain',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({size = DEFAULT_SIZE}) => size,
    height: ({size = DEFAULT_SIZE}) => size,
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height: '75%',
    background: 'no-repeat center',
    backgroundSize: 'cover',
    borderRadius: '50%',
  },
});

export const Avatar = memo(function Avatar(props: Props) {
  const {className, children, imageUrl, size, invert, ...rest} = props;
  const mc = useStyles(props);

  return (
    <div className={c(mc.root, className)} {...rest}>
      <div className={mc.image} style={{backgroundImage: `url(${imageUrl})`}}/>
      {children}
    </div>
  );
});
