import React, {memo, ReactNode, ReactNodeArray} from 'react';
import c from 'classnames';

import {Typography} from '../Typography';

import {makeStyles} from '@material-ui/styles';

import {Theme} from '../../theme';

interface Props {
  className?: string;
  children?: ReactNode | ReactNodeArray;
  onClick?(): void;
  imageUrl: string;
  title: ReactNode;
  subtitle?: string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    border: `1px solid ${theme.palette.primary}`,
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: '3px 3px 0 0',
  },
  left: {
    flex: '0 0 60px',
    height: 60,
    padding: '10px 9px 10px 10px',
    boxSizing: 'border-box',
    borderRight: `1px solid ${theme.palette.primary}`,
  },
  image: {
    width: '100%',
    height: '100%',
    background: 'no-repeat center',
    backgroundSize: 'contain',
    borderRadius: '50%',
  },
  right: {
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 16px 0 24px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 16,
    width: '100%',
    lineHeight: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    marginTop: 5,
    fontSize: 14,
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
}));

export const ProfileHead = memo(function ProfileHead(props: Props) {
  const {className, children, title, subtitle, imageUrl, ...rest} = props;
  const mc = useStyles(props);

  return (
    <div className={c(mc.root, className)} {...rest}>
      <div className={mc.left}>
        <div
          className={mc.image}
          style={{backgroundImage: `url(${imageUrl})`}}
        />
      </div>
      <div className={mc.right}>
        <Typography
          className={mc.title}
          color={'primary'}
          fontFamily={'helvetica'}
        >
          {title}
        </Typography>
        {subtitle &&
        <Typography
          className={mc.subtitle}
          color={'white'}
          fontFamily={'helvetica'}
        >
          {subtitle}
        </Typography>}
      </div>
    </div>
  );
});
