import React, {memo} from 'react';

import {Modal} from '../Modal';
import {Button} from '../Button';

import {makeStyles} from '@material-ui/styles';

import {Theme} from '../../theme';
import {ModalProps} from '../Modal';

interface Props extends Omit<ModalProps, 'onClose'> {
  onDecline(): void;
  onConfirm(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  body: {
    padding: '25px 20px',
  },
  footer: {
    display: 'flex',
    alignItems: 'stretch',
    borderTop: '1px solid #7C7F7E',
  },
  buttonWrapper: {
    flex: '0 0 50%',

    '& + &': {
      borderLeft: '1px solid #7C7F7E',
    },
  },
  button: {
    color: 'black',
  },
}), {name: 'ConfirmationModal'});

export const ConfirmationModal = memo(function ConfirmationModal(props: Props) {
  const {onDecline, onConfirm, children} = props;
  const mc = useStyles(props);

  return (
    <Modal className={mc.root} onClose={onDecline}>
      <div className={mc.body}>{children}</div>
      <div className={mc.footer}>
        <div className={mc.buttonWrapper}>
          <Button
            className={mc.button}
            variant={'tertiary'}
            fullWidth={true}
            size={'xl'}
            onClick={onConfirm}
          >
            Да
          </Button>
        </div>
        <div className={mc.buttonWrapper}>
          <Button
            className={mc.button}
            variant={'tertiary'}
            fullWidth={true}
            size={'xl'}
            onClick={onDecline}
          >
            Нет
          </Button>
        </div>
      </div>
    </Modal>
  );
});
