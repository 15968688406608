import React, {memo, ReactNode, useCallback, useEffect, useState} from 'react';

import {DefaultPanelHeader} from '../../DefaultPanelHeader';
import {PanelHead} from '../../PanelHead';
import {PanelBody} from '../../PanelBody';
import {Typography} from '../../Typography';
import {Loader} from '../../Loader';
import {PromoCode} from './PromoCode';
import {Achievement} from './Achievement';
import {SubscribeCommunityAchievement} from './SubscribeCommunityAchievement';
import {ErrorMessage} from '../../ErrorMessage';

import {useActions, useSelector} from '../../../hooks';
import {makeStyles} from '@material-ui/styles';
import {useQuery, useSubscription} from '@apollo/react-hooks';

import {
  AchievementsEnum,
  FetchAchievementsAndPromoCodesDocument,
  FetchAchievementsAndPromoCodesQuery,
  SubscribeAchievementProgressGainDocument,
  SubscribeAchievementProgressGainSubscription,
  SubscribeNewPromosDocument,
  SubscribeNewPromosSubscription,
} from '../../../types';
import {userActions} from '../../../redux/user';
import {achievementsActions} from '../../../redux/achievements';
import {Button} from '../../Button';

const useStyles = makeStyles({
  promoCodes: {
    marginTop: 30,
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
    margin: '30px -20px 0',
    width: 'calc(100% + 40px)',
  },
  promoCodesList: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'stretch',
  },
  title: {
    fontSize: 29,
    lineHeight: '29px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: 14,
  },
  subtitle: {
    fontSize: 17,
    lineHeight: '20px',
  },
  loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  achievements: {
    marginTop: 32,
  },
  achievement: {
    '& + &': {
      marginTop: 25,
    },
  },
}, {name: 'AchievementsPanel'});

export const AchievementsPanel = memo(function AchievementsPanel() {
  const mc = useStyles({});
  const [isSharingDisabled, setIsSharingDisabled] = useState(false);

  const onShareStart = useCallback(() => setIsSharingDisabled(true), []);
  const onShareEnd = useCallback(() => setIsSharingDisabled(false), []);

  // Update view data
  const {
    setAchievements, setPromoCodes, setUserAchievements,
    increaseUserAchievementProgress, addPromoCode,
  } = useActions({
    setAchievements: achievementsActions.setAchievements,
    setPromoCodes: userActions.setPromoCodes,
    addPromoCode: userActions.addPromoCode,
    setUserAchievements: userActions.setUserAchievements,
    increaseUserAchievementProgress: userActions.increaseUserAchievementProgress,
  });
  const {achievements, promoCodes, userAchievements} = useSelector(
    ({achievements, user}) => ({
      achievements: achievements.achievements,
      promoCodes: user.promoCodes,
      userAchievements: user.achievements,
    }),
  );
  const {data, error, loading, refetch} = useQuery<FetchAchievementsAndPromoCodesQuery>(
    FetchAchievementsAndPromoCodesDocument, {fetchPolicy: 'no-cache'});

  // Listen to achievements progress
  const {data: achievementsSub} = useSubscription<SubscribeAchievementProgressGainSubscription>(
    SubscribeAchievementProgressGainDocument, {shouldResubscribe: true},
  );

  // Listen to new promo codes
  const {data: promosSub} = useSubscription<SubscribeNewPromosSubscription>(
    SubscribeNewPromosDocument, {shouldResubscribe: true},
  );

  // React on new data
  useEffect(() => {
    if (data) {
      // Update user achievements progress
      setUserAchievements(data.user.achievements);

      // Update achievements
      setAchievements(data.achievements);

      // Update promo codes
      setPromoCodes(data.user.promoCodes.map(p => ({
        id: p.id,
        title: p.title,
        value: p.value,
        expiresAt: p.expiresAt,
      })));
    }
  }, [data, setAchievements, setPromoCodes, setUserAchievements]);

  // When new achievement progress was gained, write to redux
  useEffect(() => {
    if (achievementsSub) {
      const {amount, achievementId} = achievementsSub.onAchievementProgressGained;
      increaseUserAchievementProgress({id: achievementId, amount});
    }
  }, [achievementsSub, increaseUserAchievementProgress]);

  // When new promo was received, add to redux
  useEffect(() => {
    if (promosSub) {
      promosSub.onNewPromoCodes.forEach(addPromoCode);
    }
  }, [promosSub, addPromoCode]);

  let promoCodesElement: ReactNode = null;
  let bodyElement: ReactNode = null;

  if (promoCodes) {
    promoCodesElement = (
      <div id={'promo-codes'} className={mc.promoCodes}>
        <div className={mc.promoCodesList}>
          {promoCodes.map(p => <PromoCode {...p} key={p.id}/>)}
        </div>
      </div>
    );
  } else {
    if (loading) {
      promoCodesElement = (
        <div className={mc.loader}>
          <Loader/>
        </div>
      );
    }
  }

  if (achievements) {
    bodyElement = (
      <>
        <Typography
          className={mc.title}
          fontFamily={'helvetica'}
          color={'primary'}
        >
          Достижения
        </Typography>
        <Typography className={mc.subtitle} fontFamily={'iris'} color={'white'}>
          При выполнении достижений ты получаешь бесплатные часы - дерзай!
        </Typography>
        <div className={mc.achievements}>
          {achievements.map(a => {
            const {levels, title, id, about, imageUrl, type} = a;
            const progress = userAchievements
              ? userAchievements.find(a => a.id === id)?.progress || 0
              : 0;

            // Sort levels in ascending order
            levels.sort((a, b) => a.points - b.points);

            const nextLevel = levels.find(l => progress < l.points) || null;
            const nextLevelNumber = nextLevel === null
              ? null
              : levels.indexOf(nextLevel) + 1;
            const level = nextLevelNumber === null
              ? levels[levels.length - 1]
              : levels[nextLevelNumber - 2];
            const levelNumber = nextLevelNumber === null
              ? levels.length
              : nextLevelNumber - 1;
            const props = {
              className: mc.achievement,
              about,
              title,
              imageUrl,
              progress,
              level,
              levelNumber,
              nextLevel,
              nextLevelNumber,
              isSharingDisabled,
              onShareStart,
              onShareEnd,
            };

            if (type === AchievementsEnum.LivingFullLife) {
              return (
                <SubscribeCommunityAchievement
                  {...props}
                  key={id}
                  groupId={169530082}
                />
              );
            }

            return <Achievement {...props} key={id}/>;
          })}
        </div>
      </>
    );
  } else if (loading) {
    bodyElement = (
      <div className={mc.loader}>
        <Loader/>
      </div>
    );
  } else if (error) {
    bodyElement = (
      <ErrorMessage
        title={'Произошла ошибка'}
        subtitle={'Нам не удалось получить информацию о достижениях'}
      >
        <Button
          variant={'primary'}
          size={'m'}
          onClick={() => refetch()}
        >
          Попробовать ее раз
        </Button>
      </ErrorMessage>
    );
  }

  return (
    <div>
      <DefaultPanelHeader/>
      <PanelHead sectionNumber={'04'} sectionTitle={'Достижения и промокоды'}>
        {promoCodesElement}
      </PanelHead>
      <PanelBody>{bodyElement}</PanelBody>
    </div>
  );
});
