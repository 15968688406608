import React, {HTMLProps, memo, useMemo} from 'react';
import c from 'classnames';

import {makeStyles} from '@material-ui/styles';

import {ProgressBar} from '../ProgressBar';
import {Typography} from '../Typography';

import {Theme} from '../../theme';

interface Props extends HTMLProps<HTMLDivElement> {
  current: number;
  max: number;
  fullProgressTitle?: string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {},
  title: {
    marginBottom: 10,
    color: theme.palette.primary,
    fontWeight: 500,
  },
}));

export const Progress = memo(function Progress(props: Props) {
  const {current, max, className, fullProgressTitle, ...rest} = props;
  const mc = useStyles(props);
  const progress = useMemo(() => {
    return max === 0 ? 100 : current / max * 100;
  }, [current, max]);

  return (
    <div className={c(mc.root, className)} {...rest}>
      <Typography className={mc.title} fontFamily={'iris'}>
        {progress >= 100 && fullProgressTitle
          ? fullProgressTitle
          : `${current} / ${max}`}
      </Typography>
      <ProgressBar value={progress}/>
    </div>
  );
});
