import {ReduxState} from './types';
import {Store, createStore, combineReducers} from 'redux';
import {devToolsEnhancer} from 'redux-devtools-extension';

import {userReducer} from './user';
import {apolloReducer} from './apollo';
import {teamReducer} from './team';
import {clubsReducer} from './clubs';
import {achievementsReducer} from './achievements';
import {ranksReducer} from './ranks';

const reducers = combineReducers<ReduxState>({
  achievements: achievementsReducer,
  apollo: apolloReducer,
  clubs: clubsReducer,
  user: userReducer,
  ranks: ranksReducer,
  team: teamReducer,
});

export function createReduxStore(state?: Partial<ReduxState>): Store<ReduxState> {
  return createStore(reducers, state, devToolsEnhancer({}));
}
