import { Select as UISelect } from 'vkma-ui';
import { withStyles } from '@material-ui/styles';

export const Select = withStyles({
  root: {
    height: 50,
    borderRadius: 4,
    paddingTop: 17,
    paddingBottom: 17,

    '& select': {
      minHeight: '100%',
    },
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
})(UISelect);
