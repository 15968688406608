import React, {memo, useState} from 'react';

import {ProfileHead, ProfileLine, ProfileLineTitle} from '../Profile';
import {Button} from '../Button';
import {Typography} from '../Typography';

import {makeStyles, styled, withStyles} from '@material-ui/styles';
import {incline} from '../../utils';
import {useSelector, useTeamHandlers} from '../../hooks';

import {ReactComponent as MessageIcon} from '../../assets/icons/message.svg';
import {ReactComponent as InfoIcon} from '../../assets/icons/info.svg';

import {Theme} from '../../theme';

interface Props {
  className?: string;
  club?: string;
  city?: string;
  game?: string;
  imageUrl: string;
  name: string;
  rankName?: string | null;
  age: number;
  stage: string;
  playTime: string;
  about?: string | null;
  vkUserId: number;
}

const FlexLine = styled(ProfileLine)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const FlexLineTitle = withStyles({
  root: {marginBottom: 0},
})(ProfileLineTitle);

const useStyles = makeStyles<Theme, Props>(theme => ({
  buttons: {
    padding: 0,
    display: 'flex',
    alignItems: 'stretch',
  },
  button: {
    flex: '0 0 50%',
    color: '#7C7F7E',
    padding: 0,

    '& + &': {
      borderLeft: `1px solid ${theme.palette.primary}`,
    },
  },
  joinButton: {
    borderRadius: '0 0 3px 3px',
  },
}), {name: 'UserProfile'});

export const UserProfile = memo(function UserProfile(props: Props) {
  const {
    imageUrl, age, about, name, playTime, rankName, stage, vkUserId,
    className, club, game, city,
  } = props;
  const mc = useStyles(props);
  const isTeammate = useSelector(state => {
    return state.team.users.some(u => u.vkUserId === vkUserId)
  });
  const [joinTeam, , isJoining] = useTeamHandlers();
  const [isWrapped, setIsWrapped] = useState(true);

  return (
    <div className={className}>
      <ProfileHead
        imageUrl={imageUrl}
        title={name}
        subtitle={rankName || 'Ранг неизвестен'}
      />
      <FlexLine>
        <FlexLineTitle>Возраст</FlexLineTitle>
        <Typography color={'primary'}>
          {age} {incline(age, 'год', 'года', 'лет')}
        </Typography>
      </FlexLine>
      {city &&
      <FlexLine>
        <FlexLineTitle>Город</FlexLineTitle>
        <Typography color={'primary'}>{city}</Typography>
      </FlexLine>}
      {club &&
      <FlexLine>
        <FlexLineTitle>Клуб</FlexLineTitle>
        <Typography color={'primary'}>{club}</Typography>
      </FlexLine>}
      {game &&
      <FlexLine>
        <FlexLineTitle>Игра</FlexLineTitle>
        <Typography color={'primary'}>{game}</Typography>
      </FlexLine>}
      <FlexLine>
        <FlexLineTitle>Игровой стаж</FlexLineTitle>
        <Typography color={'primary'}>{stage}</Typography>
      </FlexLine>
      <FlexLine>
        <FlexLineTitle>Время игры</FlexLineTitle>
        <Typography color={'primary'}>{playTime}</Typography>
      </FlexLine>
      {!isWrapped && about &&
      <ProfileLine>
        <ProfileLineTitle color={'primary'}>О себе</ProfileLineTitle>
        <Typography color={'primary'}>{about}</Typography>
      </ProfileLine>}
      <ProfileLine className={mc.buttons}>
        <Button
          className={mc.button}
          variant={'tertiary'}
          href={`https://vk.com/id${vkUserId}`}
          size={'xl'}
          before={<MessageIcon/>}
        >
          Написать
        </Button>
        <Button
          className={mc.button}
          variant={'tertiary'}
          size={'xl'}
          before={<InfoIcon/>}
          onClick={() => setIsWrapped(isWrapped => !isWrapped)}
        >
          {isWrapped ? 'Подробнее' : 'Свернуть'}
        </Button>
      </ProfileLine>
      {!isTeammate &&
      <Button
        className={mc.joinButton}
        variant={'primary'}
        size={'xl'}
        fullWidth={true}
        onClick={() => joinTeam(vkUserId)}
        disabled={isJoining}
      >
        Объединиться в пати
      </Button>}
    </div>
  );
});
