import {ofType, unionize, UnionOf} from 'unionize';
import {
  AchievementAboutVariableFormattingEnum,
  AchievementAboutVariablesEnum,
  AchievementsEnum,
} from '../types';

type FormatOperation = {
  operation: AchievementAboutVariableFormattingEnum.Incline;
  values: string[];
};

interface Achievement {
  id: string;
  title: string;
  type: AchievementsEnum;
  imageUrl: string;
  about: {
    template: string;
    variables: Array<{
      variable: AchievementAboutVariablesEnum;
      formatOperations: FormatOperation[];
    }>;
  };
  levels: Array<{
    points: number;
    snippetImageUrl: string;
    storyImageUrl: string;
  }>;
}

export interface AchievementsReducerState {
  achievements?: Achievement[];
}

export const achievementsActions = unionize({
  setAchievements: ofType<Achievement[]>(),
}, {
  tag: 'type',
  value: 'payload',
} as const);

export type AchievementsAction = UnionOf<typeof achievementsActions>;

const initState: AchievementsReducerState = {
};

export const achievementsReducer = (
  state = initState,
  action: AchievementsAction,
): AchievementsReducerState => {
  return achievementsActions.match(action, {
    setAchievements: achievements => ({
      ...state,
      achievements,
    }),
    default: () => state,
  });
};
