import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {DefaultPanelHeader} from '../../DefaultPanelHeader';
import {PanelHead} from '../../PanelHead';
import {PanelBody} from '../../PanelBody';
import {Loader} from '../../Loader';
import {EmptyProfile} from './EmptyProfile';
import {EditProfile} from '../../EditProfile';
import {Team} from './Team';
import {SearchProfiles} from './SeachProfiles';
import {ErrorMessage} from '../../ErrorMessage';

import {makeStyles} from '@material-ui/styles';
import {useActions, useSelector} from '../../../hooks';
import {useMutation, useQuery, useSubscription} from '@apollo/react-hooks';
import {apolloActions} from '../../../redux/apollo';
import {teamActions} from '../../../redux/team';

import {
  CreateProfileDocument,
  CreateProfileMutation,
  CreateProfileMutationVariables,
  DropProfileDocument,
  DropProfileMutation,
  DropProfileMutationVariables,
  FetchTeamDocument,
  FetchTeamQuery,
  FetchTeamQueryVariables,
  SubscribeUserJoinedTeamDocument,
  SubscribeUserJoinedTeamSubscription,
  SubscribeUserLeftTeamDocument,
  SubscribeUserLeftTeamSubscription,
} from '../../../types';
import {userActions} from '../../../redux/user';
import {Button} from '../../Button';
import {useConfig} from '../../ConfigProvider';

const useStyles = makeStyles({
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const TeamPanel = memo(function TeamPanel() {
  const mc = useStyles({});
  const teamQuery = useSelector(state => state.apollo.teamQuery);
  const {
    setTeamQuery, setTeammates, setProfile, addTeammate, removeTeammate,
  } = useActions({
    setTeamQuery: apolloActions.setTeamQuery,
    setTeammates: teamActions.setTeammates,
    addTeammate: teamActions.addTeammate,
    removeTeammate: teamActions.removeTeammate,
    setProfile: userActions.setProfile,
  });
  const {launchParams: {userId}} = useConfig();
  const profile = useSelector(state => state.user.profile);
  let content: ReactNode = null;

  const [isEditShown, setIsEditShown] = useState(false);

  // Get panel data
  const {data, error, refetch} = useQuery<FetchTeamQuery, FetchTeamQueryVariables>(
    FetchTeamDocument,
    {fetchPolicy: 'network-only'},
  );
  useEffect(() => {
    if (data) {
      const {team, profile} = data.user;

      // Update query
      setTeamQuery(data);

      // Update profile
      if (profile) {
        const {playTime, stage, about, game, age, clubId} = profile;
        setProfile({
          about,
          age,
          clubId,
          gameId: game.id,
          playTimeId: playTime.id,
          stageId: stage.id,
        });
      } else {
        setProfile(null);
      }

      // Update team
      if (team) {
        setTeammates(team.users.map(u => ({
          name: u.name,
          profileImageUrl: u.profileImageUrl,
          rankName: u.rank?.title,
          vkUserId: u.vkUserId,
        })));
      }
    }
  }, [data, setTeammates, setProfile, setTeamQuery]);

  // Profile actions
  const [createProfile, createProfileResult] = useMutation<CreateProfileMutation,
    CreateProfileMutationVariables>(CreateProfileDocument);
  const [dropProfile] = useMutation<DropProfileMutation,
    DropProfileMutationVariables>(DropProfileDocument);

  useEffect(() => {
    if (createProfileResult.data) {
      const {
        playTime, stage, about, game, age, clubId,
      } = createProfileResult.data.createProfile;
      setProfile({
        about,
        age,
        clubId,
        gameId: game.id,
        playTimeId: playTime.id,
        stageId: stage.id,
      });
    }
  }, [createProfileResult, setProfile]);

  const [isSaving, setIsSaving] = useState(false);

  // Drops profile
  const onDrop = useCallback(async () => {
    setIsSaving(true);
    try {
      await dropProfile();
      setProfile(null);
      setIsEditShown(false);
    } catch (e) {
    }
    setIsSaving(false);
  }, [dropProfile, setProfile]);

  // Saves profile and refetches panel data
  const onSave = useCallback(async (
    age: number,
    clubId: string,
    gameId: string,
    stageId: string,
    playTimeId: string,
    about: string,
  ) => {
    setIsSaving(true);
    try {
      await createProfile({
        variables: {age, clubId, gameId, stageId, playTimeId, about},
      });
    } catch (e) {
    }
    setIsEditShown(false);
    setIsSaving(false);
  }, [createProfile]);

  // Subscribe for team updates
  const {data: leftUserSub} = useSubscription<SubscribeUserLeftTeamSubscription>(
    SubscribeUserLeftTeamDocument,
  );
  const {data: joinedUserSub} = useSubscription<SubscribeUserJoinedTeamSubscription>(
    SubscribeUserJoinedTeamDocument,
  );

  // Add teammate locally
  useEffect(() => {
    if (joinedUserSub) {
      addTeammate(joinedUserSub.onUserJoinedTeam);
    }
  }, [joinedUserSub, addTeammate]);

  // Remove teammate locally
  useEffect(() => {
    if (leftUserSub) {
      // In case, current user left team, we should drop his teammates
      if (leftUserSub.onUserLeftTeam === userId) {
        setTeammates([]);
      }
      // Otherwise, the other user left team, remove him from teammates
      else {
        removeTeammate(leftUserSub.onUserLeftTeam);
      }
    }
  }, [leftUserSub, removeTeammate, userId, setTeammates]);

  if (error) {
    content = (
      <ErrorMessage
        title={'Произошла ошибка'}
        subtitle={'Нам не удалось получить необходимую информацию с сервера'}
      >
        <Button
          variant={'primary'}
          size={'m'}
          onClick={() => refetch()}
        >
          Попробовать еще раз
        </Button>
      </ErrorMessage>
    );
  } else if (teamQuery) {
    const {user, cities, games, stages, playTimes, clubs} = teamQuery;
    const {profileImageUrl, rank, name} = user;

    if (isEditShown) {
      const { about, age, clubId, gameId, playTimeId, stageId } = profile || {};
      content = (
        <EditProfile
          imageUrl={profileImageUrl}
          name={name}
          rankName={rank?.title}
          age={age}
          about={about}
          clubId={clubId}
          gameId={gameId}
          playTimeId={playTimeId}
          stageId={stageId}
          cities={cities}
          games={games}
          stages={stages}
          playTimes={playTimes}
          clubs={clubs}
          isSaving={isSaving}
          isProfileCreated={!!profile}
          onSave={onSave}
          onDrop={onDrop}
          onCancel={() => setIsEditShown(false)}
        />
      );
    } else if (!profile) {
      content = (
        <EmptyProfile
          imageUrl={profileImageUrl}
          name={name}
          rankName={rank?.title}
          onFillClick={() => setIsEditShown(true)}
        />
      );
    } else {
      const { clubId, gameId } = profile;
      content = (
        <SearchProfiles
          cities={cities}
          clubId={clubId}
          games={games}
          gameId={gameId}
          imageUrl={profileImageUrl}
          onEdit={() => setIsEditShown(true)}
        />
      );
    }
  } else {
    content = (
      <div className={mc.loaderWrapper}>
        <Loader/>
      </div>
    );
  }

  return (
    <div>
      <DefaultPanelHeader/>
      <PanelHead sectionNumber={'01'} sectionTitle={'Моя команда CSA•COLIZEUM'}>
        <Team/>
      </PanelHead>
      <PanelBody>
        {content}
      </PanelBody>
    </div>
  );
});
