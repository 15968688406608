import {styled} from '@material-ui/styles';
import {Theme} from '../../theme';

export const ProfileLine = styled('div')<Theme>(({theme}) => ({
  border: `solid ${theme.palette.primary}`,
  borderWidth: '0 1px 1px',
  padding: '16px 16px',
  boxSizing: 'border-box',

  '&:first-child': {
    borderRadius: '3px 3px 0 0',
  },

  '&:last-child': {
    borderRadius: '0 0 3px 3px',
  },
}));
