import React, {memo, useCallback, useState} from 'react';

import {Button} from '../../Button';
import {Achievement, AchievementProps} from './Achievement';

import {withStyles} from '@material-ui/styles';
import vkBridge from '@vkontakte/vk-bridge';
import {useMutation} from '@apollo/react-hooks';
import {
  EmitCommunitySubscriptionEventDocument,
  EmitCommunitySubscriptionEventMutation,
  EmitCommunitySubscriptionEventMutationResult,
} from '../../../types';

interface Props extends AchievementProps {
  groupId: number;
}

const StyledButton = withStyles({root: {borderRadius: 0}})(Button);

export const SubscribeCommunityAchievement = memo(function (props: Props) {
  const {levelNumber, groupId, ...rest} = props;
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [emitEvent] = useMutation<EmitCommunitySubscriptionEventMutation,
    EmitCommunitySubscriptionEventMutationResult>(
    EmitCommunitySubscriptionEventDocument,
  );

  const onClick = useCallback(async () => {
    setIsSubscribing(true);
    try {
      await vkBridge.send('VKWebAppJoinGroup', {group_id: groupId});
      await emitEvent();
    } catch (e) {
    }
    setIsSubscribing(false);
  }, [groupId, emitEvent]);

  return (
    <Achievement
      levelNumber={levelNumber}
      {...rest}
      displayProgress={false}
      bottom={
        levelNumber === 0 &&
        <StyledButton
          variant={'primary'}
          fullWidth={true}
          onClick={onClick}
          size={'xl'}
          disabled={isSubscribing}
        >
          Подписаться на сообщество
        </StyledButton>
      }
    />
  );
});
