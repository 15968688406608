/**
 * Inclines string depending on number
 * @param count
 * @param ifOneString
 * @param ifTwoString
 * @param ifFiveString
 */
export function incline(
  count: number,
  ifOneString: string,
  ifTwoString: string,
  ifFiveString: string,
) {
  let tempNumber = Math.abs(count) % 100;

  if (tempNumber >= 5 && tempNumber <= 20) {
    return ifFiveString;
  }
  tempNumber %= 10;
  if (tempNumber === 1) {
    return ifOneString;
  }
  if (tempNumber >= 2 && tempNumber <= 4) {
    return ifTwoString;
  }

  return ifFiveString;
}

/**
 * Converts number to roman number system
 * @param {number} value
 * @returns {string}
 */
export function toRoman(value: number): string {
  let currentValue = value;
  const roman: Record<string, number> = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let result = '';

  for (const i of Object.keys(roman)) {
    const q = Math.floor(currentValue / roman[i]);
    currentValue -= q * roman[i];
    result += i.repeat(q);
  }

  return result;
}
