import React, {memo, ReactNode, ReactNodeArray} from 'react';

import {Typography} from '../Typography';

import {makeStyles} from '@material-ui/styles';

interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactNode | ReactNodeArray;
}

const useStyles = makeStyles({
  title: {
    fontSize: 17,
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 10,
  },
});

export const ErrorMessage = memo(function ErrorMessage(props: Props) {
  const {
    title = 'Ошибка',
    subtitle = 'Произошла неизвестная ошибка',
    children,
  } = props;
  const mc = useStyles(props);

  return (
    <div>
      <Typography
        className={mc.title}
        color={'primary'}
        fontFamily={'helvetica'}
      >
        {title}
      </Typography>
      <Typography className={mc.subtitle} color={'secondary'}>
        {subtitle}
      </Typography>
      {children}
    </div>
  );
});
