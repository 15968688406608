import React, {memo, useCallback, useState} from 'react';

import {Button} from '../../Button';
import {Avatar} from '../../Avatar';
import {Typography} from '../../Typography';
import {RouterLink} from '../../RouterLink';

import {makeStyles} from '@material-ui/styles';
import {useSelector, useTeamHandlers} from '../../../hooks';

import {PanelsEnum, ViewsEnum} from '../../../types';

import {Theme} from '../../../theme';
import {useConfig} from '../../ConfigProvider';
import {ConfirmationModal} from '../../ConfirmationModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: 30,
    position: 'relative',
    zIndex: 1,
  },
  listWrapper: {
    overflow: 'hidden',
    margin: '0 -20px',
    width: 'calc(100% + 40px)',
  },
  list: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'stretch',
  },
  userWrapper: {
    padding: '0 7.5px',
    minWidth: 285,

    '&:first-child': {
      paddingLeft: 20,
    },
    '&:last-child': {
      paddingRight: 20,
    },
  },
  user: {
    backgroundColor: theme.palette.primary,
    padding: 10,
    borderRadius: 5,
    display: 'flex',
  },
  userImage: {
    flex: '0 0 80px',
  },
  userInfo: {
    paddingLeft: 12,
  },
  userName: {
    fontSize: 14,
    lineHeight: '17px',
    marginBottom: 4,
    textTransform: 'uppercase',
  },
  userRank: {
    fontSize: 12,
  },
  profileButton: {
    borderColor: 'black',
    color: 'black',
    marginTop: 9,
  },
  leaveButton: {
    marginTop: 18,
  },
  confirmText: {
    fontSize: 15,
    lineHeight: '17px',
    textAlign: 'center',
  },
}));

export const Team = memo(function Team() {
  const mc = useStyles({});
  const users = useSelector(state => state.team.users);
  const [, leaveTeam, isLeaving] = useTeamHandlers();
  const {launchParams: {userId}} = useConfig();
  const filteredUsers = users.filter(u => u.vkUserId !== userId);

  const [isConfirmShown, setIsConfirmShown] = useState(false);
  const onConfirm = useCallback(() => {
    setIsConfirmShown(false);
    return leaveTeam();
  }, [leaveTeam]);

  if (filteredUsers.length === 0) {
    return null;
  }

  return (
    <div className={mc.root}>
      <div className={mc.listWrapper}>
        <div className={mc.list}>
          {filteredUsers.map(({vkUserId, name, profileImageUrl, rankName}) => (
            <div className={mc.userWrapper} key={vkUserId}>
              <div className={mc.user}>
                <Avatar
                  className={mc.userImage}
                  imageUrl={profileImageUrl}
                  size={80}
                  invert={true}
                />
                <div className={mc.userInfo}>
                  <Typography
                    className={mc.userName}
                    color={'black'}
                    fontFamily={'helvetica'}
                  >
                    {name}
                  </Typography>
                  <Typography className={mc.userRank} color={'secondary'}>
                    Ранг: {rankName || 'Неизвестно'}
                  </Typography>
                  <RouterLink
                    to={{
                      view: ViewsEnum.Main,
                      panel: PanelsEnum.Profile,
                      query: {vkUserId: vkUserId.toString()},
                    }}
                  >
                    <Button
                      className={mc.profileButton}
                      variant={'outline'}
                      size={'m'}
                    >
                      Анкета
                    </Button>
                  </RouterLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button
        className={mc.leaveButton}
        variant={'outline'}
        size={'l'}
        onClick={() => setIsConfirmShown(true)}
        disabled={isLeaving}
      >
        Выйти из команды
      </Button>
      {isConfirmShown &&
      <ConfirmationModal
        onDecline={() => setIsConfirmShown(false)}
        onConfirm={onConfirm}
      >
        <Typography className={mc.confirmText}>
          Вы действительно хотите покинуть команду?
        </Typography>
      </ConfirmationModal>}
    </div>
  );
});
