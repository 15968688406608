import { makeStyles } from '@material-ui/styles';

export const legacyUseStyles = makeStyles(() => ({
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}), {
  name: 'ClubsPanel'
});
