import React, {
  memo,
  ReactNode,
  ReactNodeArray,
  useCallback, useMemo,
  useState,
} from 'react';
import c from 'classnames';

import {Typography} from '../../Typography';
import {Button} from '../../Button';
import {Progress} from '../../Progress';
import {SharingSelection} from '../../SharingSelection';

import {makeStyles} from '@material-ui/styles';

import {ReactComponent as ShareIcon} from '../../../assets/icons/share.svg';
import whiteStripesImage from '../../../assets/white-stripes.png';

import {useConfig} from '../../ConfigProvider';
import {shareStory, shareWallPost, toRoman} from '../../../utils';
import vkBridge from '@vkontakte/vk-bridge';

import {Theme} from '../../../theme';
import {AchievementAbout, AchievementLevel} from '../../../types';
import {formatAbout} from './utils';
import {useGetShortLink} from '../../../hooks';

export interface AchievementProps {
  className?: string;
  title: string;
  imageUrl: string;
  progress: number;
  level: AchievementLevel | null;
  levelNumber: number;
  nextLevelNumber: number | null;
  nextLevel: AchievementLevel | null;
  about: AchievementAbout;
  displayProgress?: boolean;
  isSharingDisabled: boolean;
  bottom?: ReactNode | ReactNodeArray;
  onShareStart(): void;
  onShareEnd(): void;
}

const useStyles = makeStyles<Theme, AchievementProps>(theme => ({
  root: {
    border: `1px solid ${theme.palette.primary}`,
  },
  body: {
    padding: '25px 20px',
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    flex: '0 0 90px',
    height: 90,
    background: 'no-repeat center',
    backgroundSize: 'contain',
  },
  meta: {
    paddingLeft: 25,
    color: 'white',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 16,
    lineHeight: '17px',
    marginBottom: 7,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 17,
    lineHeight: '18px',
    wordBreak: 'break-word',
  },
  progress: {
    marginTop: 20,
  },
  levels: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    lineHeight: '14px',
    marginTop: 10,
  },
  buttons: {
    display: 'flex',
    alignItems: 'stretch',
    borderTop: `1px solid ${theme.palette.primary}`,
  },
  button: {
    border: 'none',
    flex: '1 0 50%',

    '& + &': {
      borderLeft: `1px solid ${theme.palette.primary}`,
    },
  },
  modal: {
    borderRadius: 18,
    backgroundColor: theme.palette.primary,
    position: 'relative',
    paddingTop: 23,
    width: '100%',
  },
  modalDismiss: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    height: 58,
    width: 58,
  },
  modalLevel: {
    background: `url(${whiteStripesImage}) no-repeat left center`,
    backgroundSize: 'contain',
    width: 168,
    paddingLeft: 38,
    fontSize: 11,
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  modalContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 22px',
  },
  modalImageWrapper: {
    flex: '0 0 90px',
    backgroundColor: 'black',
    borderRadius: 5,
    height: 90,
    padding: 5,
    boxSizing: 'border-box',
  },
  modalImage: {
    background: 'black no-repeat center',
    backgroundSize: 'contain',
    height: '100%',
    width: '100%',
  },
  modalText: {
    paddingLeft: 24,
    fontSize: 15,
    lineHeight: '17px',
  },
}));

export const Achievement = memo(function Achievement(props: AchievementProps) {
  const {
    title, imageUrl, progress, className, levelNumber,
    nextLevelNumber, level, nextLevel, isSharingDisabled, onShareEnd,
    onShareStart, displayProgress = true, bottom, about,
  } = props;
  const mc = useStyles(props);
  const [showShareSelection, setShowShareSelection] = useState(false);
  const {launchParams: {appId}} = useConfig();
  const getShortLink = useGetShortLink();
  const progressLeft = useMemo(() => {
    if (nextLevel === null) {
      return null;
    }
    const progressLeft = nextLevel.points - progress;
    return progressLeft < 0 ? 0 : progressLeft;
  }, [nextLevel, progress]);

  // Shares rank via wall post
  const onShareWallClick = useCallback(async () => {
    if (level) {
      onShareStart();
      try {
        const link = `https://vk.com/app${appId}`;
        const shortLink = await getShortLink(link);
        await shareWallPost(
          `Вход только для лучших игроков! Ты готов?: ${shortLink}`,
          link,
          level.snippetImageUrl,
        );
      } catch (e) {
      }
      onShareEnd();
    }
  }, [onShareStart, onShareEnd, getShortLink, level, appId]);

  // Shares rank via story
  const onShareStoryClick = useCallback(async () => {
    if (level) {
      onShareStart();
      try {
        await shareStory(`https://vk.com/app${appId}`, level.storyImageUrl);
      } catch (e) {
      }
      onShareEnd();
    }
  }, [onShareStart, onShareEnd, appId, level]);

  // Opens share selection menu or shares via post in case, story is not
  // supported
  const onShareClick = useCallback(() => {
    if (vkBridge.supports('VKWebAppShowStoryBox')) {
      return setShowShareSelection(true);
    }
    return onShareWallClick();
  }, [onShareWallClick]);

  return (
    <div className={c(mc.root, className)}>
      <div className={mc.body}>
        <div className={mc.main}>
          <div
            className={mc.image}
            style={{backgroundImage: `url(${imageUrl})`}}
          />
          <div className={mc.meta}>
            <Typography className={mc.title} fontFamily={'helvetica'}>
              {title}
            </Typography>
            <Typography className={mc.description}>
              {progressLeft === null
                ? (levelNumber === 1
                  ? 'Достижение получено'
                  : 'Достигнут максимальный уровень достижения')
                : formatAbout(about, progressLeft)}
            </Typography>
          </div>
        </div>
        {displayProgress && nextLevel &&
        <>
          <Progress
            className={mc.progress}
            current={progress}
            max={nextLevel.points}
            fullProgressTitle={'Максимальный уровень'}
          />
          <Typography
            className={mc.levels}
            color={'primary'}
            fontFamily={'iris'}
          >
            {levelNumber === 0
              ? <div/>
              : <div>{toRoman(levelNumber)} уровень</div>}
            {nextLevelNumber && <div>{toRoman(nextLevelNumber)} уровень</div>}
          </Typography>
        </>}
      </div>
      {level &&
      <div className={mc.buttons}>
        <Button
          className={mc.button}
          variant={'secondary'}
          before={<ShareIcon/>}
          size={'xl'}
          disabled={isSharingDisabled}
          onClick={onShareClick}
        >
          Поделиться
        </Button>
      </div>}
      {bottom}
      {showShareSelection &&
      <SharingSelection
        onClose={() => setShowShareSelection(false)}
        onStoryClick={onShareStoryClick}
        onWallClick={onShareWallClick}
      />}
    </div>
  );
});
