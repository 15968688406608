import React, {memo} from 'react';

import {Input} from '../Input';
import ReactInputMask, {Props} from 'react-input-mask';

export const InputMask = memo(function InputMask(props: Omit<Props, 'children'>) {
  return (
    <ReactInputMask {...props}>
      {/* @ts-expect-error */}
      {(inputProps: any) => <Input {...inputProps}/>}
    </ReactInputMask>
  );
});
