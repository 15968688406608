import React, {
  ChangeEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {InputMask} from '../InputMask';
import {Button} from '../Button';
import {PanelHead} from '../PanelHead';
import {PanelBody} from '../PanelBody';
import {DefaultPanelHeader} from '../DefaultPanelHeader';
import {Typography} from '../Typography';

import {
  ActivateCheckDocument,
  ActivateCheckMutation,
  ActivateCheckMutationVariables, APIErrorsEnum,
} from '../../types';

import {isAPIError} from '../../utils';
import {useMutation} from '@apollo/react-hooks';
import c from 'classnames';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100% - 1px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  head: {
    flex: '0 0 auto',
  },
  body: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  controls: {
    flex: '0 0 auto',
  },
  title: {
    fontSize: 17,
    lineHeight: '15px',
    marginBottom: 10,
  },
  button: {
    display: 'table',
    margin: 'auto',
    marginTop: 15,
  },
  text: {
    fontSize: 17,
    marginTop: 10,
    animation: '$text-appear 300ms',
  },
  textError: {
    color: 'red',
  },
  policy: {
    flex: '1 0 auto',
    paddingTop: 15,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
  },
  policyTop: {
    fontSize: 15,
    lineHeight: '18px',
    marginBottom: 18,
  },
  policyBottom: {
    fontSize: 12,
    lineHeight: '14px',
  },
  '@keyframes text-appear': {
    from: {opacity: 0},
    to: {opacity: 1},
  },
}), {name: 'ActivateCheckPanel'});

export const ActivateCheckPanel = memo(function () {
  const mc = useStyles({});
  const [value, setValue] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hint, setHint] = useState<string | null>(null);
  const [activateCheck] = useMutation<ActivateCheckMutation,
    ActivateCheckMutationVariables>(ActivateCheckDocument);

  const canSend = useMemo(() => {
    return !!value
      .replace(/\D/g, '')
      .match(/^\d{12}$/);
  }, [value]);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setError(null);
    setHint(null);
  }, []);

  const onButtonClick = useCallback(async () => {
    setIsSending(true);
    try {
      await activateCheck({
        variables: {value: value.replace(/\D/g, '')},
      });
      setHint('Чек успешно активирован');
      setError(null);
      setValue('');
    } catch (e) {
      // In case, API error occurred and error is check
      if (
        isAPIError(e) &&
        e.graphQLErrors.some(err => {
          return err.name === APIErrorsEnum.CheckActivationDayLimitReached;
        })
      ) {
        setError(
          'Превышен лимит регистрации чеков. В день можно регистрировать ' +
          'не более 2 чеков',
        );
      } else {
        setError('Произошла неизвестная ошибка');
      }
    }
    setIsSending(false);
  }, [value, activateCheck]);

  return (
    <div className={mc.root}>
      <DefaultPanelHeader/>
      <PanelHead
        className={mc.head}
        sectionNumber={'03'}
        sectionTitle={'Регистрация чеков'}
      />
      <PanelBody className={mc.body}>
        <div className={mc.controls}>
          <label>
            <Typography className={mc.title} fontFamily={'iris'}
                        color={'white'}>
              Номер чека
            </Typography>
            <InputMask
              mask={'9 9 9 9 9 9 9 9 9 9 9 9'}
              maskChar={'_'}
              alwaysShowMask={true}
              value={value}
              onChange={onChange}
              disabled={isSending}
            />
          </label>
          {hint &&
          <Typography className={mc.text} color={'primary'}>{hint}</Typography>}
          {error &&
          <Typography className={c(mc.text, mc.textError)}>{error}</Typography>}
          <Button
            className={mc.button}
            variant={'primary'}
            size={'xl'}
            disabled={isSending || !canSend}
            onClick={onButtonClick}
          >
            Отправить
          </Button>
        </div>
        <div className={mc.policy}>
          <Typography
            className={mc.policyTop}
            color={'white'}
            fontFamily={'helvetica'}
          >
            Введи номер чека из любого клуба COLIZEUM и получи шанс выиграть
            до 3 000 рублей на баланс своего профиля!
          </Typography>
          <div className={mc.policyBottom}>
            <Typography color={'white'} fontFamily={'helvetica'}>
              <b>Условия участия:</b>
            </Typography>
            <Typography color={'white'} fontFamily={'helvetica'}>
              В поле можно вводить не более 2 номеров своих чеков каждый
              календарный день по Мск. Можно регистрировать чеки от 100
              рублей<br/>
              <br/>
              Каждый понедельник, в 12:00 (Мск) мы случайным образом проводим
              выбор номеров победивших чеков.<br/>
              <br/>
              При победе в вашем профиле появится промокод, который необходимо
              показать администратору клуба, где был получен чек, сообщить
              свой номер телефона и получить призовые на баланс вашего профиля.
            </Typography>
          </div>
        </div>
      </PanelBody>
    </div>
  );
});
