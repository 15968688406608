import React from 'react';

import { PanelBody } from '../../../../PanelBody';
import { RouterLink } from '../../../../RouterLink';
import { ErrorMessage } from '../../../../ErrorMessage';
import { Button } from '../../../../Button';

export const ClubPanelNotFound = () => (
  <PanelBody>
    <ErrorMessage
      title="Клуб не найден"
      subtitle="Нам не удалось найти информацию об этом клубе"
    >
      <RouterLink pop>
        <Button variant="primary" size="m">Вернуться</Button>
      </RouterLink>
    </ErrorMessage>
  </PanelBody>
)
