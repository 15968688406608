import React, {memo, useCallback, useEffect, useMemo} from 'react';

import {AppTree} from '../AppTree';
import {Modal} from '../Modal';
import {Typography} from '../Typography';
import {Button} from '../Button';

import {
  useActions,
  useDoubleTapHandler,
  useRouter,
  useSelector,
} from '../../hooks';
import {makeStyles} from '@material-ui/styles';
import {viewsTree} from '../../trees';
import {userActions} from '../../redux/user';
import {useMutation, useSubscription} from '@apollo/react-hooks';

import {
  MarkNotificationAsReadDocument,
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables,
  NotificationTypesEnum,
  PanelsEnum,
  SubscribeNewNotificationsDocument,
  SubscribeNewNotificationsSubscription,
  SubscribeNewNotificationsSubscriptionVariables,
  ViewsEnum,
} from '../../types';

const useStyles = makeStyles({
  notificationTitle: {
    fontSize: 18,
    paddingTop: 20,
    textAlign: 'center',
  },
  modalRoot: {
    '& > *:not(:first-child)': {
      display: 'none',
    },
  },
  notificationButton: {
    marginTop: 20,
    color: 'black',
    borderColor: 'black',
  },
}, {name: 'App'});

/**
 * Visual entry of application
 * @type {React.NamedExoticComponent<object>}
 */
export const App = memo(function App() {
  const mc = useStyles({});
  const router = useRouter();
  const {view, panel} = router.currentState;
  const onTouchStart = useDoubleTapHandler();
  const [mutateMarkAsRead] = useMutation<MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument);
  const {markNotificationAsRead, addNotification} = useActions({
    markNotificationAsRead: userActions.markNotificationAsRead,
    addNotification: userActions.addNotification,
  });
  const notification = useSelector(state => state.user.notifications[0]);

  const onModalClose = useCallback(() => {
    if (notification) {
      markNotificationAsRead(notification.id);
      mutateMarkAsRead({variables: {notificationId: notification.id}});
    }
  }, [notification, markNotificationAsRead, mutateMarkAsRead]);

  const notificationButton = useMemo(() => {
    if (!notification) {
      return null;
    }

    if (
      notification.type === NotificationTypesEnum.WelcomePromoCode ||
      notification.type === NotificationTypesEnum.PromoCodeReceived ||
      notification.type === NotificationTypesEnum.LotteryWon
    ) {
      return (
        <Button
          className={mc.notificationButton}
          variant={'outline'}
          fullWidth={true}
          size={'l'}
          onClick={() => {
            onModalClose();

            if (
              view !== ViewsEnum.Main ||
              panel !== PanelsEnum.Achievements
            ) {
              router.pushState({
                view: ViewsEnum.Main,
                panel: PanelsEnum.Achievements,
              });
            } else {
              // Scroll to promo codes list
              document.getElementById('promo-codes')?.scrollIntoView({
                behavior: 'smooth',
              });
            }
          }}
        >
          Посмотреть
        </Button>
      );
    } else if (notification.type === NotificationTypesEnum.UserJoinedTeam) {
      return (
        <Button
          className={mc.notificationButton}
          variant={'tertiary'}
          fullWidth={true}
          size={'l'}
          onClick={() => {
            onModalClose();

            if (
              view !== ViewsEnum.Main ||
              panel !== PanelsEnum.Team
            ) {
              router.pushState({
                view: ViewsEnum.Main,
                panel: PanelsEnum.Team,
              });
            } else {
              // Scroll to teammates list
              document.getElementById('team')?.scrollIntoView({
                behavior: 'smooth',
              });
            }
          }}
        >
          Посмотреть
        </Button>
      );
    }

    return null;
  }, [
    notification, view, panel, mc.notificationButton, router,
    onModalClose,
  ]);

  const {data: subscription} = useSubscription<SubscribeNewNotificationsSubscription,
    SubscribeNewNotificationsSubscriptionVariables>(
    SubscribeNewNotificationsDocument, {
      fetchPolicy: 'network-only',
      shouldResubscribe: true,
    });

  // Add handler which prevents scroll after double tapping the screen
  useEffect(() => {
    const body = document.documentElement;
    body.addEventListener('touchstart', onTouchStart, {passive: false});

    return () => body.removeEventListener('touchstart', onTouchStart);
  }, [onTouchStart]);

  // When new notifications appeared, add them to redux
  useEffect(() => {
    if (subscription) {
      subscription.onNewNotifications.forEach(addNotification);
    }
  }, [subscription, addNotification]);

  return (
    <>
      <div id={'modal-root'} className={mc.modalRoot}/>
      {notification &&
      <Modal onClose={onModalClose}>
        <Typography className={mc.notificationTitle}>
          {notification.title}
        </Typography>
        {notificationButton}
      </Modal>}
      <AppTree tree={viewsTree} activeView={view} activePanel={panel}/>
    </>
  );
});
