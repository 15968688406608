import React, { useState } from 'react';

import { TFullClub } from '../../../../../types/club';

import { PanelBody } from '../../../../PanelBody';
import { Typography } from '../../../../Typography';

import { legacyUseStyles } from './helpers/legacyUseStyles';
import { Link } from 'vkma-ui';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';
import Tariffs from '../../Tariffs';
import {ShareIcon} from './components/ShareIcon';

interface ClubPanelInfoProps {
  club: TFullClub;
}

export const ClubPanelInfo = ({ club: { title, address, phone, description, social, slider, prices } }: ClubPanelInfoProps) => {
  const mc = legacyUseStyles();
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className={mc.body}>
      <div className={mc.top}>
        <div className={mc.topLeft}>
          <Typography className={mc.title} fontFamily="helvetica">
            #{title}
          </Typography>
          {address && <Typography>{address}</Typography>}
          {phone && <Typography><a href={`tel:${phone}`}>{phone}</a></Typography>}
        </div>
        <Typography className={mc.topRight} fontFamily="marsh">
          24
        </Typography>
      </div>
      <PanelBody className={mc.bottom}>
        <Typography className={mc.title} fontFamily="helvetica">
          {title}
        </Typography>
        {description && (
          <Typography className={mc.description}>
            {description}
          </Typography>
        )}
        <div className={mc.socials}>
          {social.map((socialItem) => (
            <Link
              key={socialItem.link}
              className={mc.social}
              href={socialItem.link}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <ShareIcon name={socialItem.icon} />
            </Link>
          ))}
        </div>
      </PanelBody>
      {slider &&
        <div className={mc.sliderContainer}>
          <SwipeableViews axis="x" index={activeSlide} onChangeIndex={(slide) => setActiveSlide(slide)}>
            {slider.map((url, index) => (
              <img
                key={index}
                className={mc.image}
                height={400}
                width="100%"
                src={url}
                alt="Club cover"
              />
            ))}
          </SwipeableViews>
          {slider.length > 1 &&
            <div className={mc.sliderDots}>
              {new Array(slider.length).fill(null).map((_, index) => (
                <div
                  key={index}
                  className={classNames(mc.sliderDot, { [mc.sliderDotActive]: activeSlide === index })}
                />
              ))}
            </div>}
        </div>}
      {prices && <Tariffs tariffs={prices} />}
    </div>
  );
};
