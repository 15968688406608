import {ChangeEvent, useCallback, useMemo, useState} from 'react';

export interface IdTitlePair {
  id: string;
  title: string;
}

export function useSelectMeta(
  valuesOrGetValues: IdTitlePair[] | (() => IdTitlePair[]),
  initialValue?: string | null,
): [
  string | null,
  IdTitlePair[],
  (e: ChangeEvent<HTMLSelectElement>) => void,
  (value: string | null) => void,
] {
  const [value, setValue] = useState(typeof initialValue === 'undefined'
    ? null
    : initialValue);
  const values = useMemo(() => {
    if (typeof valuesOrGetValues === 'function') {
      return valuesOrGetValues();
    }
    return valuesOrGetValues;
  }, [valuesOrGetValues]);
  const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  }, []);

  return [value, values, onChange, setValue];
}
